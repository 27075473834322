import React, { Component } from "react";
import $ from "jquery"
import moment from 'moment';

import arrowLeft from "../img/arrow-thick-left.svg"
import arrowRight from "../img/arrow-thick-right.svg"
import axios from 'axios';
import Swal from "sweetalert2";
class DateSelector extends Component {
	state = {
        date: this.props.date || moment().format("YYYY-MM-DD"),
        baseApiUrl: "https://server2.icollector.biz/api",
        stateNow:false,
	};
    
    componentDidMount(){
        //console.log(this.props);
        this.setState({ stateNow: localStorage.getItem("serverState") });
        $('#txtSelectedDate').val(this.state.date);
        $('#txtSelectedDate').datepicker({
            autoclose: true,
            todayHighlight: true,
            todayBtn: true
        }).on('changeDate', (selected) => {
            var date = $('#txtSelectedDate').val();
            this.setState({ date });

            this.props.onDateChanged(date);
        });

        this.onCheckStatusCore();
        
        



    }

    onNextDay = () => {
        console.log(this.props);
        let value = moment(this.state.date, "YYYY-MM-DD").add(1, 'd');
        let date = value.format("YYYY-MM-DD");
        this.setState({ date });
        $('#txtSelectedDate').val(date);
        this.props.onDateChanged(date);
    }

    onPreviousDay=()=>{
        let value = moment(this.state.date, "YYYY-MM-DD").add(-1, 'd');
        let date = value.format("YYYY-MM-DD");
        this.setState({ date });
        $('#txtSelectedDate').val(date);
        this.props.onDateChanged(date);
    }

     onCheckStatusCore = () =>
    {
        

     const token = localStorage.getItem("tokenManager");
        console.log("base URL ", this.state.baseApiUrl);
        const url = this.state.baseApiUrl + "/patients/status?token=" + token ;
       
		axios.get(url).then((res) => {
            let data = res.data;
            
            console.log(data);
            this.setState({ state: data });
		});
    }

    onRestartCore = () => {
        

    Swal.fire({
				title: "Confirm",
				text: "Are you sure to restart iCollectorCore ? ",
				icon: "question",
				showCancelButton: true,
				showConfirmButton: true,
				focusConfirm: false,
				iconColor: "#3085d6",
				confirmButtonText: "restart",
				cancelButtonText: "Cancel",
				confirmButtonColor: "#DC3545",
				cacnelButtonColor: "#d33",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: "Please Wait !",
						html: "Restarting ...", // add html attribute if you want or remove
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: false,
						willOpen: () => {
							Swal.showLoading();
						},
					});
                const token = localStorage.getItem("tokenManager");
                console.log("base URL ", this.state.baseApiUrl);
                const url = this.state.baseApiUrl + "/patients/restart?token=" + token;      
                axios.get(url).then((res) => {
                let data = res.data;
                    
                console.log(data);
							

                          var content = `Restarting iCollectoCore  finished !.`;
							Swal.fire({
								title: "Finished",
								html: content,
								icon: "info",
                            });
                    this.setState({ stateNow: true });
                                localStorage.setItem('serverState', true);


                     window.location.reload();
						})
						.catch((error) => {
							Swal.fire({
								html: error.response.data, // add html attribute if you want or remove
								showConfirmButton: true,
								icon: "error",
							});
						});
				}
			});

    
	


        
    }
     onstartCore = () => {
        
         	Swal.fire({
				title: "Confirm",
				text: "Are you sure to start iCollectorCore ? ",
				icon: "question",
				showCancelButton: true,
				showConfirmButton: true,
				focusConfirm: false,
				iconColor: "#3085d6",
				confirmButtonText: "Start",
				cancelButtonText: "Cancel",
				confirmButtonColor: "#DC3545",
				cacnelButtonColor: "#d33",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: "Please Wait !",
						html: "Starting ... ", // add html attribute if you want or remove
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: false,
						willOpen: () => {
							Swal.showLoading();
						},
					});
                const token = localStorage.getItem("tokenManager");
                console.log("base URL ", this.state.baseApiUrl);
                const url = this.state.baseApiUrl + "/patients/start?token=" + token;      
                axios.get(url).then((res) => {
                let data = res.data;
                    
                console.log(data);
							

                          var content = `Starting iCollectoCore  finished !.`;
							Swal.fire({
								title: "Finished",
								html: content,
								icon: "info",
                            });
                    this.setState({ stateNow: true });
                    localStorage.setItem('serverState', true);

                     window.location.reload();
						})
						.catch((error) => {
							Swal.fire({
								html: error.response.data, // add html attribute if you want or remove
								showConfirmButton: true,
								icon: "error",
							});
						});
				}
			});

    
	


    }

      onCheckStatusCore = () =>
    {
        

         const token = localStorage.getItem("tokenManager");
         const baseApiUrl = "https://server2.icollector.biz/api";
        console.log("base URL ", this.state.baseApiUrl);
        
        const url = baseApiUrl + "/patients/status?token=" + token ;
       
		axios.get(url).then((res) => {
            let data = res.data;
            
            console.log(data);
            this.setState({ stateNow: data });

		});
    }


     onStopCore = () => {
        

    Swal.fire({
				title: "Confirm",
				text: "Are you sure to stop iCollectorCore ? ",
				icon: "question",
				showCancelButton: true,
				showConfirmButton: true,
				focusConfirm: false,
				iconColor: "#3085d6",
				confirmButtonText: "Stop",
				cancelButtonText: "Cancel",
				confirmButtonColor: "#DC3545",
				cacnelButtonColor: "#d33",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: "Please Wait !",
						html: "Stopping ...", // add html attribute if you want or remove
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: false,
						willOpen: () => {
							Swal.showLoading();
						},
					});
                const token = localStorage.getItem("tokenManager");
                console.log("base URL ", this.state.baseApiUrl);
                const url = this.state.baseApiUrl + "/patients/stop?token=" + token;      
                axios.get(url).then((res) => {
                let data = res.data;
                this.setState({ stateNow: false });
                localStorage.setItem('serverState', false);   
                console.log(this.state.stateNow);
							

                          var content = `Stopping iCollectoCore  finished !.`;
							Swal.fire({
								title: "Finished",
								html: content,
								icon: "info",
                            });
                

                     window.location.reload();
						})
						.catch((error) => {
							Swal.fire({
								html: error.response.data, // add html attribute if you want or remove
								showConfirmButton: true,
								icon: "error",
							});
						});
				}
			});

    
	




    }

    render() {
        
		return (
			<div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <div className="input-group " style={{ width: "max-content", float: "right", height: "100%", alignContent: "center" }}>
                    
					<div className="input-group-prepend" style={{ height: "30px", width: "50px" }}>
						<button className="btn btn-secondary" onClick={this.onPreviousDay}>
							<img src={arrowLeft} className="icon-svg" alt="" />
						</button>
					</div>
					<input
						type="text"
						id="txtSelectedDate"
						placeholder="Select Date"
						data-date-format="yyyy-mm-dd"
						style={{ width: "120px" }}
						className="form-control text-input datepicker"
						name="SelectedDate"
						readOnly="readonly"
						defaultValue=""
					/>
                    
					<div className="input-group-append" style={{ height: "30px", width: "50px" }}>
						<button id="nextDay" className="btn btn-secondary" onClick={this.onNextDay}>
							<img src={arrowRight} className="icon-svg" alt="" />
                        </button>
                     
                    </div>
                    
                    <div className="dropdown" style={{marginTop:"-1%"}}>
                        {this.state.stateNow ?
                            <button
                                className="btn btn-secondary dropdown-toggle m-1"
                                type="button"
                                id="btn-Settings"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ width: "90px", backgroundColor: "green" }}
                            >
                                <i className="fas fa-cog"></i>
                            </button> :
                            <button
                                className="btn btn-secondary dropdown-toggle m-1"
                                type="button"
                                id="btn-Settings"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ width: "90px", backgroundColor: "red" }}
                            >
                                <i className="fas fa-cog"></i>
                            </button>}
							 <div className="dropdown-menu" aria-labelledby="btn-Settings" style={{left:'-36%'}} > 
								<a
									id="btn-SettingItems"
									type="button"
									title="this feature is  restart iCollectorCore"
									onClick={this.onRestartCore}
									className="dropdown-item"
									data-toggle="modal"
									data-target="#modalPopup"
									>
									Restart
                            </a>
                            {!this.state.stateNow?
								<a
									id="btn-SettingPlugin"
									type="button"
									title="this feature is  start iCollectorCore"
									onClick={this.onstartCore}
									className="dropdown-item"
									data-toggle="modal"
									data-target="#modalPopup"
									>
									Start
                                </a>
                                :
                                	<a
									id="btn-ConnectionParamaterPlugin"
									type="button"
									title="this feature is stop iCollectorCore"
									onClick={this.onStopCore}
									className="dropdown-item"
									data-toggle="modal"
									data-target="#modalPopup"
									>
									Stop
                                </a>}
							</div></div>

                    </div>
            </div>
            
		);
	}
}

export default DateSelector;
