import React, { Component } from "react";
import PatientsView from "./patientsView";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

class PatientsViewManager extends Component {
	constructor(props) {
		super(props);
		//console.log("manager", props);
		const { date, baseApiUrl, apiToken } = this.props;

		const token = localStorage.getItem('tokenManager');
		this.checkTimestamp();

		const alias = localStorage.getItem('alias');
		
		let selDate=localStorage.getItem('date');
		
		this.patients = React.createRef();
		let formattedDate = moment(date, "YYYY-MM-DD");
		if (!formattedDate.isValid()) 
		{
			if(selDate===null || selDate===null)
				formattedDate = moment().format("YYYY-MM-DD");
			else
				formattedDate=selDate;
		}
		else 
		{
			formattedDate = formattedDate.format("YYYY-MM-DD");
		}

		this.state = {
			tokenManager: token,
			apiToken: apiToken,
			instrumentToken: "",
			date: formattedDate,
			alias: alias,
			baseApiUrl: baseApiUrl,
			showDetails: false,
			instruments: [],
		};
	}

	componentWillReceiveProps(props){
		const { date, baseApiUrl, apiToken } = props;
		this.setState({ date ,baseApiUrl, apiToken});
	}

	
	isTokenValid = (token) => {
		return token !== null && token !== undefined && token !== "";
	};

	loadPatients = (instrument) => {
		//this.getInstrumentToken(instrument);
		const url = this.state.baseApiUrl + "/patients/token";
		axios.post(url, null, {
				headers: {
					username: instrument.username,
					password: instrument.password,
					token: this.state.apiToken,
				},
			})
			.then((result) => {
				let token = result.data.token;
				if(token===null || token===undefined)
					token='';					
				let instruments = [...this.state.instruments];
				let newInstrument = instruments[instruments.indexOf(instrument)];
				newInstrument.token = token;
				//this.setState({instrumentToken:token, instruments });

				localStorage.setItem('token', token);
				localStorage.setItem('alias', instrument.name);
				this.setState({ instrumentToken: token, alias: instrument.name, instruments });
				
				this.patients.current.loadTable(instrument.token, this.state.date);
				this.patients.current.loadInstrumentInfo(instrument.token);
				
				if (!this.isTokenValid(instrument.token)) {
					Swal.fire({
						html: "Error loading instrument data", // add html attribute if you want or remove
						showConfirmButton: true,
						icon: "error",
					});
				}
				
			})
			.catch((error) => {});
		
		
	};

	checkTimestamp(){
		const timestamp = localStorage.getItem('timestamp');
		let formattedTimestamp = moment(timestamp, "YYYY-MM-DD HH:mm:ss");
		let isValid=timestamp!=null;
		//console.log('timestamp read : '+timestamp);
		if (formattedTimestamp.isValid()) 
		{
			const diff =  moment().diff(formattedTimestamp, 'minutes');
			//console.log('diff : '+diff);
			if(diff>=30)
				isValid=false;
		}
		else 
		{
			isValid=false;
		}
		//console.log('timestamp validity : '+isValid);
		if(!isValid)
		{
			localStorage.removeItem('tokenManager');
			localStorage.removeItem('token');
			localStorage.removeItem('timestamp');
			localStorage.removeItem('date');
			
			const url = "/";
			window.location.href = url;
		}
	}


	onConnectionSelect=(id)=>{
		let instrument = this.state.instruments.find((i) => i.id == id);
        console.log(instrument);
		this.loadPatients(instrument);
	};

	componentDidMount() {
		//console.log('componentDidMount',this.state);
		if(this.state.baseApiUrl===null|| this.state.baseApiUrl==="")
		{
			console.log("api url is not defined");
			return;		
		}
		const url = this.state.baseApiUrl + "/patients/getinstruments?token=" + this.state.tokenManager;
		axios
			.get(url)
			.then((result) => {
				const connections = result.data;
				if (connections) {
					const alias = localStorage.getItem('alias');
					this.setState({ instruments: connections });
					let instrument=connections[0];
					if(alias!="")
					{
						instrument = this.state.instruments.find((i) => i.name == alias);		
					}

					this.loadPatients(instrument);
				}
			})
			.catch((error) => {});
	}

	onDateChange = (date) => {
		this.setState({ date });
	};

	render() {
		//console.log('render', this.state);
		return (
			<div className="vertical-center">
				<div>
					<PatientsView
						//token={this.state.instrumentToken}
						//managerToken = {this.state.tokenManager}
						alias={this.state.alias}
						date={this.state.date}
						baseApiUrl={this.state.baseApiUrl}
						onDateChange={this.onDateChange}
						fromManager="1"
						instruments={this.state.instruments}
						onConnectionSelect={this.onConnectionSelect}
						ref={this.patients}
					></PatientsView>
				</div>
			</div>
		);
	}
}

export default PatientsViewManager;
