import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2"


class PatientResults extends Component {
	state = {
        baseApiUrl:'',
		visible: this.props.visible || false,
		patientKey: 0,
        patientID : '',
        patientTime :'',
        patientDate :'',
		token : ''
	};

	UNSAFE_componentWillReceiveProps (nextProps) {
		const token=localStorage.getItem('token');
		this.setState({ visible: nextProps.visible, patientKey: nextProps.patientKey, baseApiUrl:nextProps.baseApiUrl, token});
	}

    loadResultsTable = (key) => {
        axios.get(this.state.baseApiUrl + "/patients/details?token="+this.state.token+"&id=" + key).then((res) => {
            let data = res.data.values;
            this.setState({patientName : res.data.patient.id, patientTime:res.data.patient.time, patientDate:res.data.patient.date});
			let showValue =this.onShowValue;
            $("#tblValues").dataTable({
				"dom": 'Bfrtip',
                responsive: false,
                autoWidth: false,
                filter: true,
                //paginate: true,
                info: false,
				processing:true,
				"language": {
					processing: "<div id='loader'>Loading</div>"
				},
                data : data,
                columns: [
                    {
                        data: "name",
                        width: "30%",
						render: function(data, type, full, meta) {
							if(type === 'display') {
							   // Return element with title and value.  This is only returned for display
							   return '<span title="' + data + '">' + data + '</span>';
							}					
							// Return raw data for sorting and filtering
							return data;
						  }
                    },
                    {
                        data: "value",
                        sortable: false,
                    },
                    
                    {
                        data: "value",
                        sortable: false,
                        searchable: false,
                        className: "column-center",
                        width: "60px",
                        "render": function (data) {
							if(data!=null && data.length>20)
                            	return '<button class="btn btn-info btnValues" data-value="'+data+'">Details</button>';
							else
								return null;
                        }
                    }
                ],
                initComplete: function () {
					this.fnAdjustColumnSizing();
					//call your function here
                    $("#tblValues").attr("style", "visibility: visible")
					$("#tblValues").on("click", ".btnValues", function (event) {
						event.preventDefault();
						let value = this.getAttribute("data-value");
						showValue(value);
					});
				},
                
            });
        });
        
    };

    onShowValue = (value) => {
        //this.setState({ visible: false });
        let replaceble = ['ABS','ACC','ADD','ADJ','AFF','AIG','AIL','AIP','AIS','AL1','APR','ARQARV','','AUT','BHS','BLC','BLG','BPO','BPX','BTS','BTX','BUI','CDM','CDO','CERCM0','','CM1','CM2','CNS','CON','CSP','CSR','CSS','CTD','CTI','DB1','DG1','DMIDON','DRG','DSC','DSP','ECD','ECR','EDU','EQP','EQU','ERR','EVN','FAC','FHSFT1','FTS','GOL','GP1','GP2','GT1','IAM','IAR','IIM','ILT','IN1','IN2','IN3INV','IPC','IPR','ISD','ITM','IVC','IVT','LAN','LCC','LCH','LDP','LOC','LRL','MFA','MFE','MFI','MRG','MSA','MSH','NCK','NDS','NK1','NPU','NSC','NST','NTE','OBR','OBX','ODS','ODT','OH1','OH2','OH3','OH4','OM1','OM2','OM3','OM4','OM5','OM6','OM7','OMC','ORC','ORG','OVR','PAC','PCE','PCR','PD1','PDA','PDC','PEO','PES','PID','PKG','PM1','PMT','PR1','PRA','PRB','PRC','PRD','PRT','PSG','PSH','PSL','PSS','PTH','PV1','PV2','PYE','QAK','QID','QPD','QRI','RCP','RDF','RDT','REL','RF1','RFI','RGS','RMI','ROL','RQ1','RQD','RXA','RXC','RXD','RXE','RXG','RXO','RXRRXV','SAC','SCD','SCH','SCP','SDD','SFT','SGH','SGT','SHP','SID','SLTSPM','STF','STZ','TCC','TCD','TQ1','TQ2','TXA','UAC','UB1','UB2','VARVND','ZL7'];
        for (let i = 0; i < replaceble.length;i++)
            value = value.replace(replaceble[i], '<br>'+replaceble[i]);
        console.log(value)
         document.getElementsByClassName("fade modal show")[0].style.display = "none";
        Swal.fire({
            title : 'Content',
            html: '<section style="text-align:initial">'+value+'</section>',
            width: '1000px',
            textAlign: 'intial',
			
        }).then((result) => { document.getElementsByClassName("fade modal show")[0].style.display = "block";   }).catch((error) => { })
       
    };



	render() {
		
		return (
			<Modal show={this.state.visible} width="400" height="300" effect="fadeInUp" onHide={this.props.onClose}>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.patientName} [{this.state.patientDate} {this.state.patientTime}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<table
							id="tblValues"
							className="table table-striped table-bordered dt-responsive nowrap"
							style={{ width: "100%", visibility:"collapse" }}
							cellSpacing="0"
						>
							<thead>
								<tr>
									<th>Paramter Name</th>
									<th>Value</th>
									<th></th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={() => this.props.onClose()}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default PatientResults;
