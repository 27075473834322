import React, { Component } from "react";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import PatientsViewToolbar from "./PatientsViewToolbar";
import PatientsTable from "./patientsTable";
import DateSelector from "./dateSelector";
import PatientResults from "./patientResults";
import InstrumentSelector from "./instrumentSelector";

import logo from "../img/icollector_logo.png";
import SearchButton from "./searchButton";


class PatientsView extends Component {
	constructor(props) {
		super(props);
		const {  date, baseApiUrl, fromManager, alias } = this.props;
		let {token} = this.props;
		if(token===undefined || token===null || token==='')
			token = localStorage.getItem('token');
		else
			localStorage.setItem('token', token);
		
		let selDate=localStorage.getItem('date');
		this.results = React.createRef();
		
		let formattedDate = moment(date, "YYYY-MM-DD");
		if (!formattedDate.isValid()) 
		{
			if((selDate===null || selDate===null) && fromManager==="0")
				formattedDate = moment().format("YYYY-MM-DD");
			else
				formattedDate=selDate;
		}
		else 
		{
			formattedDate = formattedDate.format("YYYY-MM-DD");
		}

		this.state = {
			token,
			date: formattedDate,
			alias,
			patientKey: 0,
			toolbarSet: false,
			tblPatients: null,
			baseApiUrl: baseApiUrl,
			showDetails: false,
			fromManager,
			isReporting : false

		};
	}

	componentWillReceiveProps(nextProps) {
		 const { alias, baseApiUrl } = nextProps;
		 this.setState({ alias, baseApiUrl });
		 
	}


	 loadInstrumentInfo=(token)=>{
		axios.get(this.state.baseApiUrl + "/patients/getinstrument?token=" + token).then((res) => {
			const result = res.data;
			localStorage.setItem('alias', result.name);
			this.setState({ alias: result.name, isReporting : result.isReporting});
		});		
	}

	prepareForm = (token, date) => {
		this.loadInstrumentInfo(token);

		this.loadTable(token, date);

		this.setState({ toolbarSet: true });
	};

	loadTable = (token, date) => {
		let baseApiUrl = this.state.baseApiUrl;
		$.fn.dataTable.ext.errMode = "none";
		let showDetails = this.onShowDetails;
		//console.log(token, date);
		if ($.fn.dataTable.isDataTable("#example")) {
			let tblPatients = this.state.tblPatients.DataTable();
			tblPatients.ajax.url(baseApiUrl + "/patients?token=" + token + "&date=" + date).load();
			$("#example").off("click",".btnDetails");
			$("#example").on("click", ".btnDetails", function (event) {
				event.preventDefault();
				let id = this.getAttribute("data-id");
				showDetails(id);
				
			});
		} else {
			
			let tblPatients = $("#example").dataTable({
				dom: 'lf<"toolbarRow">rtip',
				responsive: false,
				stateSave: true,
				autoWidth: false,
				destroy:true,
				processing:true,
				"language": {
					processing: "<div id='loader'>Loading</div>"
				},
				
				aaSorting: [[1,'asc']],
				ajax: {
					url: baseApiUrl + "/patients?token=" + token + "&date=" + date,
					dataSrc: "",
					type: "GET",
					async: true
				},
				select: {
					style: "multi",
					selector: "td:first-child",
				},
				columns: [
					{
						defaultContent: "",
						className: "select-checkbox",
						width: "20px",
						sortable: false,
						checkboxes: {
							selectRow: true,
						},
					},
					{
						data: "rowIndex",
						
						searchable: false,
						className: "column-center td-tight col-sm-1 col-md-1",
						width: "10px",
					},
					{
						data: "found",
						sortable: false,
						searchable: false,
						className: "column-center",
						width: "20px",
						render: function (data) {
							if (data === 1) {
								return '<label class="circleGreen"></label>';
							} 
							if (data === -1) {
								return '<label class="circleGrey"></label>';
							} 
							else {

								return '<label class="circleRed"></label>';
							}
						},
					},
					{
						data: "id",
						className: "column-bold",
					},
					// {
					// 	data: "name",
					// 	width: "10%",
					// },
					{
						data: "sampleInformation",
						width: "30%",
					},
					{
						data: "source",
						width: "15%",
					},
					{
						data: "time",
						searchable: false,
						className: "column-center",
						width: "2%",
					},
					{
						data: "uploaded",
						searchable: false,
						className: "column-center",
						width: "2%",
						render: function (data) {
							if (data === true) {
								return '<div><i class= "fa fa-check" style="color:limegreen;font-size: 1.5em;"></i></div>';
							} else {
								return '<div> <i class= "fa fa-times" style="color:red;font-size: 1.5em;"></i></div>';
							}
						},
					},
					{
						data: "key",
						sortable: false,
						searchable: false,
						className: "column-center",
						width: "70px",
						render: function (data) {
							//var url = "/patients/details?security=" + token + "&id=" + data;
							return '<button class="btn btn-info btnDetails" data-id=' + data + " >Details</button>";
						},
					},
				],
				initComplete: function () {
					this.fnAdjustColumnSizing();
					// $('#example').show();
					$("#example").attr("style", "visibility: visible")
					$("#example").off("click",".btnDetails");
					$("#example").on("click", ".btnDetails", function (event) {
						event.preventDefault();
						let id = this.getAttribute("data-id");
						showDetails(id);
					});
				},
			});
            this.setState({ tblPatients });
		}
		
		if (!this.state.toolbarSet)
		{ 
			$("div.toolbarRow").append($("#toolbar"));
			//console.log($("#btnSearch"));
			$("#example_filter").append($(btnSearch));
		}
	};

	loadTableSearch = (token, date1, date2, sampleName, pName, pValue) => {
		let baseApiUrl = this.state.baseApiUrl;
		$.fn.dataTable.ext.errMode = "none";
		let showDetails = this.onShowDetails;
		const url = baseApiUrl + "/patients/search?token=" + token + "&date1=" + date1+ "&date2=" + date2
					+"&sampleid=" + sampleName+ "&pname=" + pName+ "&pvalue=" + pValue;
		if ($.fn.dataTable.isDataTable("#example")) {
			let tblPatients = this.state.tblPatients.DataTable();
			tblPatients.ajax.url(url).load();
			$("#example").off("click",".btnDetails");
			$("#example").on("click", ".btnDetails", function (event) {
				event.preventDefault();
				let id = this.getAttribute("data-id");
				showDetails(id);
				
			});
		} else {
			
			let tblPatients = $("#example").dataTable({
				dom: 'lf<"toolbarRow">rtip',
				responsive: false,
				stateSave: true,
				autoWidth: false,
				destroy:true,
				processing:true,
				"language": {
					processing: "<div id='loader'>Loading</div>"
				},
				
				aaSorting: [[1,'asc']],
				ajax: {
					url: url,
					dataSrc: "",
					type: "GET",
					async: true
				},
				select: {
					style: "multi",
					selector: "td:first-child",
				},
				columns: [
					{
						defaultContent: "",
						className: "select-checkbox",
						width: "20px",
						sortable: false,
						checkboxes: {
							selectRow: true,
						},
					},
					{
						data: "rowIndex",
						
						searchable: false,
						className: "column-center td-tight col-sm-1 col-md-1",
						width: "10px",
					},
					{
						data: "found",
						sortable: false,
						searchable: false,
						className: "column-center",
						width: "20px",
						render: function (data) {
							if (data === 1) {
								return '<label class="circleGreen"></label>';
							} 
							if (data === -1) {
								return '<label class="circleGrey"></label>';
							} 
							else {

								return '<label class="circleRed"></label>';
							}
						},
					},
					{
						data: "id",
						className: "column-bold",
					},
					// {
					// 	data: "name",
					// 	width: "10%",
					// },
					{
						data: "sampleInformation",
						width: "10%",
					},
					{
						data: "source",
						width: "15%",
					},
					{
						data: "time",
						searchable: false,
						className: "column-center",
						width: "50px",
					},
					{
						data: "uploaded",
						searchable: false,
						className: "column-center",
						width: "40px",
						render: function (data) {
							if (data === true) {
								return '<div><i class= "fa fa-check" style="color:limegreen;font-size: 1.5em;"></i></div>';
							} else {
								return '<div> <i class= "fa fa-times" style="color:red;font-size: 1.5em;"></i></div>';
							}
						},
					},
					{
						data: "key",
						sortable: false,
						searchable: false,
						className: "column-center",
						width: "70px",
						render: function (data) {
							//var url = "/patients/details?security=" + token + "&id=" + data;
							return '<button class="btn btn-info btnDetails" data-id=' + data + " >Details</button>";
						},
					},
				],
				initComplete: function () {
					this.fnAdjustColumnSizing();
					// $('#example').show();
					$("#example").attr("style", "visibility: visible")
					$("#example").off("click",".btnDetails");
					$("#example").on("click", ".btnDetails", function (event) {
						event.preventDefault();
						let id = this.getAttribute("data-id");
						showDetails(id);
					});
				},
			});
			this.setState({ tblPatients });
		}
		
		if (!this.state.toolbarSet)
		{ 
			$("div.toolbarRow").append($("#toolbar"));
			//console.log($("#btnSearch"));
			$("#example_filter").append($(btnSearch));
		}
	};

	componentDidMount() {
		if (this.state.fromManager === "0") this.prepareForm(this.state.token, this.state.date);
	}

	onDateChanged = (date) => {
		localStorage.setItem('date',date);
		if (this.props.onDateChange) this.props.onDateChange(date);
		const token = localStorage.getItem('token');
		this.setState({ date });
		this.loadTable(token, date);
	};

	onShowDetails = (key) => {
		this.setState({ showDetails: true, patientKey: key });

		this.results.current.loadResultsTable(key);
	};

	onCloseDetails = () => {
		this.setState({ showDetails: false });
	};

	onSearch=(date1, date2, sampleName, paramName, paramValue)=>{
		const token = localStorage.getItem('token');
		this.loadTableSearch(token, date1, date2, sampleName, paramName, paramValue);

	}

	render() {
		
		return (
			<div className="container-fluid" style={{ minHeight: 580 }}>
				<PatientResults
					ref={this.results}
					visible={this.state.showDetails}
					patientKey={this.state.patientKey}
					baseApiUrl={this.state.baseApiUrl}
					onClose={this.onCloseDetails}
					//token={this.state.token}
				/>

				<div className="card" style={{ padding: "10px" }}>
					<div className="alert div-padding">
						<div className="row">
							<div className="col-sm-12 col-md-6 col-lg-8 col-xl-9">
								<div className="input-group">
									<div className="div-app-tile">
										<img src={logo} className="logo img-fluid" alt="" />
										
										{ this.props.fromManager==="1" ?
											<InstrumentSelector
												baseApiUrl={this.state.baseApiUrl}
												onSelect={this.props.onConnectionSelect}
												caption = {this.state.alias}
												//managerToken= {this.props.managerToken}
											></InstrumentSelector>
											:
											<label className="label-title m-2">{this.state.alias}</label>
										}
									</div>
								</div>
							</div>
							<DateSelector onDateChanged={this.onDateChanged} date={this.state.date} />
						</div>
					</div>

					<div style={{ height: "10px" }}></div>
						<PatientsViewToolbar
							alias={this.state.alias}
							date={this.state.date}
							baseApiUrl={this.state.baseApiUrl}
							isReporting = {this.state.isReporting}
							fromManager = {this.state.fromManager}
						/>

						<SearchButton baseApiUrl={this.state.baseApiUrl} onSearch={this.onSearch} alias={this.state.alias}></SearchButton>

					<PatientsTable />
				</div>
			</div>
		);
	}
}

export default PatientsView;
