import React, { Component } from "react";
import $ from "jquery"
import moment from 'moment';


class DateSelector2 extends Component {
	state = {
		id : this.props.id,
		date: this.props.date || moment().format("YYYY-MM-DD"),
	};

    componentDidMount=()=>{
		const id= '#'+ this.state.id;
        $(id).val(this.state.date);
        
		$(id).datepicker({
            autoclose: true,
            todayHighlight: true,
            todayBtn: true
        }).on('changeDate', (selected) => {
            var date = $(id).val();
            this.setState({ date });

          //  this.props.onDateChanged(date);
        });
    }

  
	render() {
		return (
			<input
				type="text"
				id={this.state.id}
				placeholder="Select Date"
				data-date-format="yyyy-mm-dd"
				style={{ width: "120px",display:"inline", marginLeft:5, marginRight:5}} 
				className="form-control text-input datepicker"
				name="SelectedDate"
				readOnly="readonly"
				defaultValue=""
			/>
		);
	}
}

export default DateSelector2;
