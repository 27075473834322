import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Toaster from "./toast";
import axios from "axios";
import Swal from "sweetalert2";

class SettingsPluginEdit extends Component {
	constructor(props) {
		super(props);

		this.refName = React.createRef();
		this.refValue = React.createRef();
		this.state = {
			visible: this.props.visible || false,
			id: this.props.id|| 0,
			baseApiUrl: this.props.baseApiUrl|| "",
			record: {},
			alias : ""
		};
	}

	onClose = () => {
		this.setState({id:0, record:{}});		
		this.props.onClose(false);
	};

	componentWillReceiveProps(nextProps) {
		this.setState({id:0, record:{}});		
		this.setState({ visible: nextProps.visible, baseApiUrl: nextProps.baseApiUrl, id: nextProps.id, alias : nextProps.alias });
		
		//if (nextProps.id > 0) this.onLoad(nextProps.id);
	}

	onLoad = (id) => {
		
		const token = localStorage.getItem("tokenManager");
		const url = this.state.baseApiUrl + "/patients/settingPlugin?token=" + token + "&id=" + id;
		axios.get(url).then((res) => {
			let data = res.data;
			this.setState({ record: data });
		});
	};

	onSave = () => {
		const name= this.refName.current.value;
		if(name.trim()==""||name==null)
		{
			Swal.fire({
				title: "Error",
				html: "Paramter name should not be empty",
				icon: "error",
			});
			return;
		}
		const value= this.refValue.current.value;		
		const token = localStorage.getItem("tokenManager");
		 
		if(this.state.id===0)
		{
			let url = this.state.baseApiUrl + "/patients/settingsPlugin/create?token=" + token+"&alias="+this.state.alias;
			// let  data={
			// 	'name':name,
			// 	'value':value,
			// };
			// let data = JSON.stringify({
			// 	'name':name,
			// 	'value':value,
			// })
			var body = new FormData();
			body.append('name', name);
			body.append('value', value);

			axios
				.put(url,body,null)
				.then((res) => {
					const data = res.data;
					//console.log(data);
					const result = data["result"];
					const message = data["message"];
					if (result && result === true) {
						
						Toaster('success', 'Record successfully added.')
						this.props.onClose(true);
					} else {
						Swal.fire({
							title: "Adding failed",
							html: message,
							icon: "error",
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		else{
			let url = this.state.baseApiUrl + "/patients/settingsPlugin/update?token=" + token + "&id=" + this.state.id;
		
			// const data={
			// 	'name':name,
			// 	'value':value,
			// };
			// let data = JSON.stringify({
			// 	'name':name,
			// 	'value':value,
			// })
			var body = new FormData();
			body.append('name', name);
			body.append('value', value);

			axios
				.post(url,body,null)
				.then((res) => {
					const data = res.data;
					//console.log(data);
					const result = data["result"];
					const message = data["message"];
					if (result && result === true) {
						
						Toaster('success', 'Record successfully updated.')
						this.props.onClose(true);
					} else {
						Swal.fire({
							title: "Updating failed",
							html: message,
							icon: "error",
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		

	};

	render() {
		return (
			<Modal
				id="modal-edit"
				dialogClassName="modal-edit"
				contentClassName="modal-edit"
				show={this.state.visible}
				effect="fadeInUp"
				onHide={() => this.onClose()}
				centered
				scrollable={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.record.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="form-group">
							<label className="label-form" htmlFor="Name">Parameter Name :</label>
							<input
								id="Name"
								name="Name"
								className="form-control input-form"
								//placeholder="Name"
								ref={this.refName}
								defaultValue={this.state.record.name}
							></input>
						</div>
						<div className="form-group">
							<label className="label-form" htmlFor="Value">Parameter Value :</label>
							{/* <input
								id="Value"
								name="Value"
								className="form-control input-form"
								//placeholder="Value"
								defaultValue={this.state.record.value}
								ref={this.refValue}
							></input> */}
							<textarea
								id="Value"
								name="Value"
								className="form-control input-form"
                                rows="10"
                                style={{height:"390px"}}
								wrap="off"
								//style={{whiteSpace:"nowrap"}}
								defaultValue={this.state.record.value}
                                ref={this.refValue}
                                
							></textarea>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="success" onClick={this.onSave}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default SettingsPluginEdit;
