import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";

import "../css/settings.css";
import SettingsEdit from "./settingsEdit";
import SettingsPluginEdit from "./settingsPluginEdit";
import ConnectionEdit from "./connectionEdit";

const columnsItems = [
	{
		data: "name",
	},
	{
		data: "id",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "50px",
		render: function (data) {
			// return '<button href="#" class="btnEdit" data-id=' + data + '"><i class= "fa fa-edit text-warning" style="font-size: 1.2em"></button>';
			return (
				'<button data-toggle="modal" data-target="#modalPopup" class="btn btn-small btn-warning btnEdit" data-id=' +
				data +
				'><i class= "far fa-edit" style="font-size: 1.2em"></button>'
			);
		},
	},
	{
		data: "id",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "50px",
		render: function (data) {
			return (
				'<button href="#" class="btn btn-small btn-danger btnDelete" data-id=' +
				data +
				'><i class= "far fa-trash-alt" style="font-size: 1.2em"></button>'
			);
		},
	},
];

const columnsPlugin = [
	{
		data: "name",
		width: "100px",
	},
	{
		data: "value",
	},
	{
		data: "id",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "50px",
		render: function (data) {
			// return '<button href="#" class="btnEdit" data-id=' + data + '"><i class= "fa fa-edit text-warning" style="font-size: 1.2em"></button>';
			return (
				'<button data-toggle="modal" data-target="#modalPopup" class="btn btn-small btn-warning btnEdit" data-id=' +
				data +
				'><i class= "far fa-edit" style="font-size: 1.2em"></button>'
			);
		},
	},
	{
		data: "id",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "50px",
		render: function (data) {
			return (
				'<button href="#" class="btn btn-small btn-danger btnDelete" data-id=' +
				data +
				'><i class= "far fa-trash-alt" style="font-size: 1.2em"></button>'
			);
		},
	},
];



const columnsConnection = [
	{
		data: "key",
		width: "100px",
	},
	{
		data: "value",
	},
	{
		data: "id",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "50px",
        render: function (data) {
            
			// return '<button href="#" class="btnEdit" data-id=' + data + '"><i class= "fa fa-edit text-warning" style="font-size: 1.2em"></button>';
			return (
				'<button disabled data-toggle="modal" data-target="#modalPopup" class="btn btn-small btn-warning btnEdit" data-id=' +
				data +
				'><i class= "far fa-edit" style="font-size: 1.2em"></button>'
			);
		},
	},
	{
		data: "id",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "50px",
		render: function (data) {
			return (
				'<button disabled href="#" class="btn btn-small btn-danger btnDelete" data-id=' +
				data +
				'><i class= "far fa-trash-alt" style="font-size: 1.2em"></button>'
			);
		},
	},
];




class InstrumentSettings extends Component {
	constructor(props) {
		super(props);
		this.settingsEdit = React.createRef();
        this.settingsPluginEdit = React.createRef();
        this.connectionEdit = React.createRef();
		this.state = {
			baseApiUrl: "",
			alias: "",
			tblSettings: null,
			visible: this.props.visible || false,
			showEditSetting: false,
			selected_id: 0,
			settingsType: 0, ////0:items settings , 1: plugin configs.
		};
	}

	onClose = () => {
		this.setState({ visible: false });
	};

	componentWillReceiveProps(nextProps) {
		this.setState({
			visible: nextProps.visible,
			baseApiUrl: nextProps.baseApiUrl,
			alias: nextProps.alias,
			settingsType: nextProps.settingsType,
		});
	}


    loadSettingsTable = (token, alias) => {
        console.log("TYPE : ", this.state.settingsType)
		//console.log(token, alias);
		let url = this.state.baseApiUrl + "/patients/settings?token=" + token + "&alias=" + alias;
		let columns = columnsItems;

		if (this.state.settingsType === 1) {
			url = this.state.baseApiUrl + "/patients/settingsPlugin?token=" + token + "&alias=" + alias;
			columns = columnsPlugin;
        }

        if (this.state.settingsType === 2)
        {
            url = this.state.baseApiUrl + "/patients/connection?token=" + token + "&alias=" + alias;
            columns = columnsConnection; 
            console.log(columns);
            }
        
        
		let deleteSetting = this.onDeleteSetting;
		let editSetting = this.onEditSetting;
		let onNewRecord = this.onNewRecord;
		if (this.state.tblSettings !== null) {
			//$("div.main-container").append($("#btnNew"));
			let table = $("#tblSettings").DataTable();
			table.clear();
			table.destroy();
			$("#tblSettings").off("click", ".btnDelete");
			$("#tblSettings").off("click", ".btnEdit");

		}

		axios.get(url).then((res) => {
            let datas = res.data;
            if (this.state.settingsType === 2) {
                console.log("old data", datas);
                var items = []
                for (var key in datas)
                    for (var k in key)
                        items.push(datas[key[k]]);
  
                var a = []
                Object.entries(items[0]).forEach(itm => a.push({ key: itm[0], value: itm[1] , id :datas[0]["mId"]+"++"+itm[0]  }));
                datas = a; 
            }
            console.log("datas ", datas);
			let tblSettings = $("#tblSettings").DataTable({
				dom: 'Bf<"toolbarSettings">rtip',
				responsive: false,
				autoWidth: false,
				info: false,
				processing:true,
				"language": {
					processing: "<div id='loader'>Loading</div>"
				},
				//data: data,
				ajax: function (data, callback, settings) {
					callback({ data: datas }); //reloads data
				},
				columns: columns,
				initComplete: function () {
					this.fnAdjustColumnSizing();
					//call your function here
					$("#tblSettings").attr("style", "visibility: visible")
					$("#tblSettings").on("click", ".btnDelete", function (event) {
						event.preventDefault();
						const id = this.getAttribute("data-id");
						//console.log(id);
						deleteSetting(id, $(this));
					});
					$("#tblSettings").on("click", ".btnEdit", function (event) {
						event.preventDefault();
						const id = this.getAttribute("data-id");
						console.log("btnEdit", id);
						editSetting(id, $(this));
					});
				},
			});

            this.setState({ tblSettings });
            if (this.state.settingsType !== 2) {
                const btnNew = '<button id="btnNew" class="btn btn-secondary">New Record</button>';
                $("div.toolbarSettings").append(btnNew);
            }
			$("#btnNew").on("click", function (event) {
				onNewRecord();
			});
		});

		//}
	};

	onDeleteSetting = (id, button) => {
		Swal.fire({
			title: "Confirm",
			text: "Are you sure to delete this record?",
			icon: "question",
			showCancelButton: true,
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#3085d6",
			confirmButtonText: "Delete",
			cancelButtonText: "Cancel",
			confirmButtonColor: "#DC3545",
			cacnelButtonColor: "#d33",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				const token = localStorage.getItem("tokenManager");
				let url = this.state.baseApiUrl + "/patients/settings/delete?token=" + token + "&id=" + id;
				if(this.state.settingsType===1){
					url = this.state.baseApiUrl + "/patients/settingsPlugin/delete?token=" + token + "&id=" + id;
				}
				axios
					.delete(url)
					.then((res) => {
						const data = res.data;
						const result = data["result"];
						const message = data["message"];
						if (result && result === true) {
							const token = localStorage.getItem("tokenManager");
							this.loadSettingsTable(token, this.state.alias);

							let tblSettings = this.state.tblSettings.DataTable();
							tblSettings.row(button.parents("tr")).remove().draw();

							Swal.fire({
								title: "Deleted",
								html: "Record successfully deleted",
								icon: "success",
							});
						} else {
							Swal.fire({
								title: "Deletion failed",
								html: message,
								icon: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error);
						// Swal.fire({
						//     html: error.response.data, // add html attribute if you want or remove
						//     showConfirmButton: true,
						//     icon: "error",
						// });
					});
			}
		});
	};

	onEditSetting = (id, button) => {
		//console.log(id);
		this.setState({ selected_id: id, showEditSetting: true });
        if (this.state.settingsType === 1) this.settingsPluginEdit.current.onLoad(id);
        else if (this.state.settingsType === 2) this.connectionEdit.current.onLoad(id);
		else this.settingsEdit.current.onLoad(id);
	};

	onCloseEditSetting = (isUpdaated) => {
		this.setState({ showEditSetting: false, selected_id: 0 });
		if (isUpdaated) {
			const token = localStorage.getItem("tokenManager");
			this.loadSettingsTable(token, this.state.alias);
		}
	};

	onNewRecord = () => {
		this.setState({ selected_id: 0, showEditSetting: true });
	};

	render() {
		return (
			<div>
				{this.state.settingsType === 0 ? (
					<SettingsEdit
						ref={this.settingsEdit}
						visible={this.state.showEditSetting}
						baseApiUrl={this.state.baseApiUrl}
						onClose={this.onCloseEditSetting}
						id={this.state.selected_id}
						alias={this.state.alias}
					/>
				) : this.state.settingsType ===1 ? (
					<SettingsPluginEdit
						ref={this.settingsPluginEdit}
						visible={this.state.showEditSetting}
						baseApiUrl={this.state.baseApiUrl}
						onClose={this.onCloseEditSetting}
						id={this.state.selected_id}
						alias={this.state.alias}
					/>
                ) : 
                <ConnectionEdit
						ref={this.connectionEdit}
						visible={this.state.showEditSetting}
						baseApiUrl={this.state.baseApiUrl}
						onClose={this.onCloseEditSetting}
						id={this.state.selected_id}
						alias={this.state.alias}
					/>
                }
				<Modal show={this.state.visible} effect="fadeInUp" onHide={() => this.props.onClose()}>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.alias}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div id="main-container">
							
							<table
								id="tblSettings"
								className="table table-striped table-bordered dt-responsive nowrap"
								style={{ width: "100%", visibility:"collapse" }}
								cellSpacing="0"
							>
								<thead>
									<tr>
										<th>Name</th>
										{this.state.settingsType === 1 ||this.state.settingsType === 2  ? <th>Value</th> : null}
										<th>Edit</th>
										<th>Delete</th>
									</tr>
								</thead>
								<tbody></tbody>
							</table>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={() => this.props.onClose()}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default InstrumentSettings;
