import React, { Component } from "react";

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from "jquery";

import LogsView from "./logsView";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

import InstrumentSettings from "./instrumentSettings";
import OrdersManager from "./orders";
import "@fortawesome/fontawesome-free";
import "@fortawesome/fontawesome-free/css/all.min.css";


class PatientsViewToolbar extends Component {
	constructor(props) {
		super(props);
		this.logs = React.createRef();
		this.settings = React.createRef();
        this.settingsPlugin = React.createRef();
        this.connectionSettings = React.createRef();
        this.orders = React.createRef();
        
		this.state = {
			showLogs: false,
			showSettings: false,
            showSettingsPlugin: false,
            showConnection:false,
			showOrders : false,
			alias: "",
			date: "",
			baseApiUrl: "",
            token: "",
            stateNow: true,
            disabled:false ,
        };
        
        
    }
    
    componentDidMount() {
        localStorage.setItem("baseApiUrl","http://127.0.0.1:5000/api");
        this.onCheckStatusCore();
        
    }

    componentWillReceiveProps(nextProps) {
        this.stateNow = localStorage.getItem('stateNow');
        this.setState({ stateNow: localStorage.getItem('stateNow') });
		const token = localStorage.getItem("token");
		this.setState({
			alias: nextProps.alias,
			date: nextProps.date,
			baseApiUrl: nextProps.baseApiUrl,
			token,
        });
       

	}

	showLogs = () => {
		this.setState({ showLogs: true });
		const token = localStorage.getItem("token");
		this.logs.current.loadLogsTable(token, this.state.date);
	};
	onCloseLogs = () => {
		this.setState({ showLogs: false });
	};

	onShowSettings = () => {
		this.setState({ showSettings: true });
		const token = localStorage.getItem("tokenManager");
		this.settings.current.loadSettingsTable(token, this.state.alias);
	};
	onCloseSettings = () => {
		this.setState({ showSettings: false });
	};


	onShowSettingsPlugin = () => {
		this.setState({ showSettingsPlugin: true });
		const token = localStorage.getItem("tokenManager");
		this.settingsPlugin.current.loadSettingsTable(token, this.state.alias);
	};
	onCloseSettingsPlugin = () => {
		this.setState({ showSettingsPlugin: false });
	};


    onShowConnectionSettings = () => {
  
		this.setState({ showConnection: true });
		const token = localStorage.getItem("tokenManager");
		this.connectionSettings.current.loadSettingsTable(token, this.state.alias);
    };
    
    onCloseConnectionSettings = () => {
		this.setState({ showConnection: false });
	};

	onExport = () => {
		var tblPatients = $("#example").DataTable();
		var results = tblPatients.rows({ selected: true }).data();
		var keys = $.map(results, function (el) {
			return el["key"];
		});
		if (keys.length <= 0) {
			Swal.fire({
				html: "No records selected to export", // add html attribute if you want or remove
				showConfirmButton: true,
				icon: "error",
			});
		} else {
			Swal.fire({
				title: "Please Wait !",
				html: "Generating data to export", // add html attribute if you want or remove
				allowOutsideClick: false,
				showCancelButton: false,
				showConfirmButton: false,
				willOpen: () => {
					Swal.showLoading();
				},
			});
			const token = localStorage.getItem("token");
			const url = this.state.baseApiUrl + "/patients/exportcsv?token=" + token + "&keys=" + JSON.stringify(keys);
			axios
				.get(url)
				.then((res) => {
					const text = res.data;
					const element = document.createElement("a");
					const file = new Blob([text], { type: "text/plain" });
					element.href = URL.createObjectURL(file);
					const fileName = "Export-" + moment().format("YYYY-MM-DD-HH-mm-ss") + ".txt";
					element.download = fileName;
					document.body.appendChild(element); // Required for this to work in FireFox
					element.click();
					Swal.close();
				})
				.catch((error) => {
					Swal.fire({
						html: error.response.data, // add html attribute if you want or remove
						showConfirmButton: true,
						icon: "error",
					});
				});
		}
	};

	onUpdateSamples = () => {
		var tblPatients = $("#example").DataTable();
		var results = tblPatients.rows({ selected: true }).data();
		var keys = $.map(results, function (el) {
			return el["key"];
		});
		if (keys.length <= 0) {
			Swal.fire({
				html: "No records selected to export", // add html attribute if you want or remove
				showConfirmButton: true,
				icon: "error",
			});
		} else {
			Swal.fire({
				title: "Confirm",
				text: "Are you sure to relink the selected samples? this may take some time.",
				icon: "question",
				showCancelButton: true,
				showConfirmButton: true,
				focusConfirm: false,
				iconColor: "#3085d6",
				confirmButtonText: "Relink",
				cancelButtonText: "Cancel",
				confirmButtonColor: "#DC3545",
				cacnelButtonColor: "#d33",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: "Please Wait !",
						html: "Processing samples", // add html attribute if you want or remove
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: false,
						willOpen: () => {
							Swal.showLoading();
						},
					});
					const parameter = keys.join(",");
					const token = localStorage.getItem("token");
					let url =
						this.state.baseApiUrl +
						"/patients/command?token=" +
						token +
						"&command=updatesamples&alias=" +
						this.state.alias +
						"&parameter=" +
						parameter;
					axios
						.post(url)
						.then((res) => {
							const data = res.data;
							const success = data["success"];
							const fail = data["fail"];

							var content = `Relinking selected samples finished, refresh list to see the status.<br/><span style="color:green;">Successful: ${success}</span>     <span style="color:red;">Failed: ${fail}</span>`;
							Swal.fire({
								title: "Finished",
								html: content,
								icon: "info",
							});
						})
						.catch((error) => {
							Swal.fire({
								html: error.response.data, // add html attribute if you want or remove
								showConfirmButton: true,
								icon: "error",
							});
						});
				}
			});
		}
	};

	onUploadSamples = () => {
		var tblPatients = $("#example").DataTable();
		var results = tblPatients.rows({ selected: true }).data();
		var keys = $.map(results, function (el) {
			return el["key"];
		});
		if (keys.length <= 0) {
			Swal.fire({
				html: "No records selected to reupload", // add html attribute if you want or remove
				showConfirmButton: true,
				icon: "error",
			});
		} else {
			Swal.fire({
				title: "Confirm",
				text: "Are you sure to reupload the selected samples? this may take some time.",
				icon: "question",
				showCancelButton: true,
				showConfirmButton: true,
				focusConfirm: false,
				iconColor: "#3085d6",
				confirmButtonText: "Resend",
				cancelButtonText: "Cancel",
				confirmButtonColor: "#DC3545",
				cacnelButtonColor: "#d33",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: "Please Wait !",
						html: "Processing samples", // add html attribute if you want or remove
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: false,
						willOpen: () => {
							Swal.showLoading();
						},
					});
					const parameter = keys.join(",");
					const token = localStorage.getItem("token");
					let url =
						this.state.baseApiUrl +
						"/patients/command?token=" +
						token +
						"&command=uploadsamples&alias=" +
						this.state.alias +
						"&parameter=" +
						parameter;
					axios
						.post(url)
						.then((res) => {
							const data = res.data;
							const success = data["success"];
							const fail = data["fail"];

							var content = `Reuploading selected samples finished, refresh list to see the status.<br/><span style="color:green;">Successful: ${success}</span>     <span style="color:red;">Failed: ${fail}</span>`;
							Swal.fire({
								title: "Finished",
								html: content,
								icon: "info",
							});
						})
						.catch((error) => {
							Swal.fire({
								html: error.response.data, // add html attribute if you want or remove
								showConfirmButton: true,
								icon: "error",
							});
						});
				}
			});
		}
	};

	onShowManageOrders = () => {
		this.setState({ showOrders: true });
		const token = localStorage.getItem("token");
		this.orders.current.loadOrdersTable(token, this.state.alias);
	};
	onCloseManageOrders = () => {
		this.setState({ showOrders: false });
    };
  /*  
    onCheckStatusCore = () =>
    {
        

     const token = localStorage.getItem("tokenManager");
        console.log("base URL ", this.state.baseApiUrl);
        const url = this.state.baseApiUrl + "/patients/status?token=" + token ;
       
		axios.get(url).then((res) => {
            let data = res.data;
            
            console.log(data);
		});
		
    }

    onRestartCore = () => {
        

     const token = localStorage.getItem("tokenManager");
        console.log("base URL ", this.state.baseApiUrl);
        const url = this.state.baseApiUrl + "/patients/restart?token=" + token ;
       
		axios.get(url).then((res) => {
            let data = res.data;
            
            console.log(data);
		});


    }
*/

     onCheckStatusCore = () =>
    {
        

         const token = localStorage.getItem("tokenManager");
         const baseApiUrl = "https://server2.icollector.biz/api";
        console.log("base URL ", this.state.baseApiUrl);
        
        const url = baseApiUrl + "/patients/status?token=" + token ;
       
		axios.get(url).then((res) => {
            let data = res.data;
            
            console.log(data);
            localStorage.setItem('serverState', data);

		});
    }

	onResetReporting = () => {
		var tblPatients = $("#example").DataTable();
		var results = tblPatients.rows({ selected: true }).data();
		var keys = $.map(results, function (el) {
			return el["key"];
		});
		if (keys.length <= 0) {
			Swal.fire({
				html: "No records selected to resubmit", // add html attribute if you want or remove
				showConfirmButton: true,
				icon: "error",
			});
		} else {
			Swal.fire({
				title: "Confirm",
				text: "Are you sure to resubmit the selected samples? this may take some time.",
				icon: "question",
				showCancelButton: true,
				showConfirmButton: true,
				focusConfirm: false,
				iconColor: "#3085d6",
				confirmButtonText: "Resubmit",
				cancelButtonText: "Cancel",
				confirmButtonColor: "#DC3545",
				cacnelButtonColor: "#d33",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: "Please Wait !",
						html: "Processing samples", // add html attribute if you want or remove
						allowOutsideClick: false,
						showCancelButton: false,
						showConfirmButton: false,
						willOpen: () => {
							Swal.showLoading();
						},
					});
					const parameter = keys.join(",");
					const token = localStorage.getItem("token");
					let url =
						this.state.baseApiUrl +
						"/patients/command?token=" +
						token +
						"&command=submitsamples&alias=" +
						this.state.alias +
						"&parameter=" +
						parameter;
					axios
						.post(url)
						.then((res) => {
							const data = res.data;
							const success = data["success"];
							const fail = data["fail"];

							var content = `Resubmitting selected samples finished, refresh list to see the status.<br/><span style="color:green;">Successful: ${success}</span>     <span style="color:red;">Failed: ${fail}</span>`;
							Swal.fire({
								title: "Finished",
								html: content,
								icon: "info",
							});
						})
						.catch((error) => {
							Swal.fire({
								html: error.response.data, // add html attribute if you want or remove
								showConfirmButton: true,
								icon: "error",
							});
						});
				}
			});
		}
	};


	onStartPlugin = () => {
		Swal.fire({
			title: "Confirm",
			text: "Are you sure to start the plugin?",
			icon: "question",
			showCancelButton: true,
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#3085d6",
			confirmButtonText: "Start",
			cancelButtonText: "Cancel",
			confirmButtonColor: "#DC3545",
			cacnelButtonColor: "#d33",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Please Wait !",
					html: "Processing samples", // add html attribute if you want or remove
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: false,
					willOpen: () => {
						Swal.showLoading();
					},
				});
				const parameter = '0';
				const token = localStorage.getItem("token");
				let url =
					this.state.baseApiUrl +
					"/patients/command?token=" +
					token +
					"&command=startplugin&alias=" +
					this.state.alias +
					"&parameter=" +
					parameter;
				axios
					.post(url)
					.then((res) => {
						const data = res.data;
						const success = data["success"];
						const fail = data["fail"];
                        this.setState({ stateNow: true });
                        this.setState({ disabled: false });
                        var content = `Plugin is requested to start. This may take a few seconds.`;
                                                window.location.reload();

						Swal.fire({
							title: "Finished",
							html: content,
							icon: "info",
						});
					})
					.catch((error) => {
						Swal.fire({
							html: error.response.data, // add html attribute if you want or remove
							showConfirmButton: true,
							icon: "error",
						});
					});
			}
		});
		
	};

	onStopPlugin = () => {
		Swal.fire({
			title: "Confirm",
			text: "Are you sure to stop the plugin?",
			icon: "question",
			showCancelButton: true,
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#3085d6",
			confirmButtonText: "Stop",
			cancelButtonText: "Cancel",
			confirmButtonColor: "#DC3545",
			cacnelButtonColor: "#d33",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Please Wait !",
					html: "Processing samples", // add html attribute if you want or remove
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: false,
					willOpen: () => {
						Swal.showLoading();
					},
				});
				const parameter = '0';
				const token = localStorage.getItem("token");
				let url =
					this.state.baseApiUrl +
					"/patients/command?token=" +
					token +
					"&command=stopplugin&alias=" +
					this.state.alias +
					"&parameter=" +
					parameter;
                console.log(url);
				axios
					.post(url)
					.then((res) => {
						const data = res.data;
						const success = data["success"];
                        const fail = data["fail"];
                          this.setState({ disabled: true });
                        this.setState({ stateNow: false });

                        var content = `Plugin is requested to stop. This may take a few seconds.`;
                                                window.location.reload();

						Swal.fire({
							title: "Finished",
							html: content,
							icon: "info",
						});
					})
					.catch((error) => {
						Swal.fire({
							html: error.response.data, // add html attribute if you want or remove
							showConfirmButton: true,
							icon: "error",
						});
					});
			}
		});
		
	};


	onRestartPlugin = () => {
		Swal.fire({
			title: "Confirm",
			text: "Are you sure to restart the plugin?",
			icon: "question",
			showCancelButton: true,
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#3085d6",
			confirmButtonText: "Start",
			cancelButtonText: "Cancel",
			confirmButtonColor: "#DC3545",
			cacnelButtonColor: "#d33",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Please Wait !",
					html: "Restarting the plugin", // add html attribute if you want or remove
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: false,
					willOpen: () => {
						Swal.showLoading();
					},
				});
				const parameter = '0';
				const token = localStorage.getItem("token");
				let url = this.state.baseApiUrl + "/patients/command?token=" +
					token +"&command=restartplugin&alias=" +
                    this.state.alias + "&parameter=" + parameter;
                console.log("url : ",url);
				axios.post(url)
					.then((res) => {
						const data = res.data;
						const success = data["success"];
						const fail = data["fail"];

						var content = `Plugin is requested to restart. This may take a few seconds.`;
						Swal.fire({
							title: "Finished",
							html: content,
							icon: "info",
						});
					})
					.catch((error) => {
						Swal.fire({
							html: error.response.data, // add html attribute if you want or remove
							showConfirmButton: true,
							icon: "error",
						});
					});
			}
		});
		
	};



	render() {
		return (
			<div id="toolbar" className="myToolbar" role="toolbar">
				<LogsView
					ref={this.logs}
					visible={this.state.showLogs}
					baseApiUrl={this.state.baseApiUrl}
					date={this.state.date}
					onClose={this.onCloseLogs}
					alias={this.state.alias}
				/>
				<InstrumentSettings
					ref={this.settings}
					visible={this.state.showSettings}
					baseApiUrl={this.state.baseApiUrl}
					onClose={this.onCloseSettings}
					alias={this.state.alias}
					settingsType={0}
				/>

				<InstrumentSettings
					ref={this.settingsPlugin}
					visible={this.state.showSettingsPlugin}
					baseApiUrl={this.state.baseApiUrl}
					onClose={this.onCloseSettingsPlugin}
					alias={this.state.alias}
					settingsType={1}
				/>

                <InstrumentSettings
					ref={this.connectionSettings}
					visible={this.state.showConnection}
					baseApiUrl={this.state.baseApiUrl}
					onClose={this.onCloseConnectionSettings}
					alias={this.state.alias}
					settingsType={2}
                />
                
				<OrdersManager
					ref={this.orders}
					visible={this.state.showOrders}
					baseApiUrl={this.state.baseApiUrl}
					onClose={this.onCloseManageOrders}
					alias={this.state.alias}
				/>


				<div className="btn-group-sm table-toolbar">
					<button
						id="btnExportTable"
						type="button"
						title="Exports selected samples and their details as CSV"
						className="btn btn-secondary"
						onClick={this.onExport}
					>
						<i className="fa fa-save"></i> Export
					</button>
					<button
						id="btn-showLog"
						type="button"
						title="Shows iCollector logs for selected date"
						onClick={this.showLogs}
						className="btn btn-secondary m-1"
						data-toggle="modal"
						data-target="#modalPopup"
					>
						<i className="fas fa-file-alt"></i> Logs
					</button>
					<span style={{ display: "inline-flex", width: "15px" }}> </span>
					
					{!this.props.isReporting ?
					<button
						id="btn-update"
						type="button"
						title="Finds selected samples in LabCollector and makes them green if found"
						onClick={this.onUpdateSamples}
						className="btn btn-secondary"
					>
						<i className="fa fa-link"></i> Re-Link
					</button>
					:null}

					{!this.props.isReporting ?
					<button
						id="btn-upload"
						type="button"
						title="Sends selected sample values to LabCollector"
						onClick={this.onUploadSamples}
						className="btn btn-secondary m-1"
						style={{ width: "90px" }}
					>
						<i className="fa fa-upload"></i> Re-Send
					</button>
					:null}
					<span style={{ display: "inline-flex", width: "15px" }}> </span>
{/* 
					{!this.props.isReporting ?
					<button
						id="btn-orders"
						type="button"
						title="Orders Manager - For Bidirectional instruments"
						onClick={this.onShowManageOrders}
						className="btn btn-secondary m-1"
						data-toggle="modal"
						data-target="#modalPopup"
						style={{ width: "90px" }}
					>
						<i className="fa fa-list-ol"></i> Orders
					</button>
					:null}

					<span style={{ display: "inline-flex", width: "15px" }}> </span> */}

					{this.props.isReporting ?
					<button
						id="btn-resetReporting"
						type="button"
						title="this feature is only for state reporting plugins to resubmit the selected samples to related state"
						onClick={this.onResetReporting}
						className="btn btn-secondary m-1"
						style={{ width: "150px" }}
						//visibility={this.props.isReporting ? "" : "hidden"}
					>
						<i className="fa fa-upload"></i> Re-Submit Message
					</button>
					:null
					}
					{this.props.fromManager === "1" ? (
						<div className="dropdown">
							<button
								className="btn btn-secondary dropdown-toggle m-1"
								type="button"
								id="btn-Settings"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								style={{ width: "90px" }}
							>
								<i className="fas fa-cog"></i> Settings
							</button>
							 <div className="dropdown-menu" aria-labelledby="btn-Settings">
								<a
									id="btn-SettingItems"
									type="button"
									title="this feature is modifying the settings of the instrument"
									onClick={this.onShowSettings}
									className="dropdown-item"
									data-toggle="modal"
									data-target="#modalPopup"
									>
									Processing parameters
								</a>
								<a
									id="btn-SettingPlugin"
									type="button"
									title="this feature is modifying the settings of the instrument"
									onClick={this.onShowSettingsPlugin}
									className="dropdown-item"
									data-toggle="modal"
									data-target="#modalPopup"
									>
									Plugin parameters
                                </a>
                                
                                	<a
									id="btn-ConnectionParamaterPlugin"
									type="button"
									title="this feature is modifying the settings of the connection"
									onClick={this.onShowConnectionSettings}
									className="dropdown-item"
									data-toggle="modal"
									data-target="#modalPopup"
									>
									Connection parameters
                                </a>
							</div>
						</div>
					) : null}
						<div className="dropdown">
							<button
								className="btn btn-secondary dropdown-toggle m-1"
								type="button"
								id="btn-connection"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								style={{ width: "110px" }}
                        >
                         
								<i className="fas fa-wifi"></i> Connection
							</button>
							 {this.props.fromManager === "1" ? (
                                this.state.stateNow !=="true" ?(
								<div className="dropdown-menu" aria-labelledby="btn-connection">
									<a
										id="btn-startplugin"
										type="button"
										title="this feature is for starting the plugin"
										onClick={this.onStartPlugin}
										className="dropdown-item"
										data-toggle="modal"
										data-target="#modalPopup"
										>
										Start Plugin
                                    </a>
                                    
							</div>):(<div className="dropdown-menu" aria-labelledby="btn-connection">		<a
										id="btn-stopplugin"
										type="button"
										title="this feature is for stopping the plugin"
										onClick={this.onStopPlugin}
										className="dropdown-item"
										data-toggle="modal"
										data-target="#modalPopup"
										>
										Stop Plugin
									</a></div>)
								) : (
									<div className="dropdown-menu" aria-labelledby="btn-connection">
										<a
											id="btn-restrartplugin"
											type="button"
											title="this feature is for restarting the plugin"
											onClick={this.onRestartPlugin}
											className="dropdown-item"
											data-toggle="modal"
											data-target="#modalPopup"
											>
											Restart Plugin
										</a>
									</div>
								)}
						</div>
					
				</div>
			</div>
		);
	}
}

export default PatientsViewToolbar;
