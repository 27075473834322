import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import DateSelector2 from "./dateSelector2";
import "../css/searchDialog.css";
import $ from "jquery";
import Select from 'react-select'
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";


class SearchButton extends Component {
    constructor(props) {
		super(props);
        this.date1=React.createRef();
        this.date2=React.createRef();
        const token = localStorage.getItem('token');
        const alias = localStorage.getItem('alias');
        this.state = {
            visible: false,
            fields : [],
            alias : alias,
            token : token,
            baseApiUrl : this.props.baseApiUrl,
            pName: ""
            
        };
    }
    
    componentWillReceiveProps (nextProps) {
        // const alias = localStorage.getItem('alias');
        // const token = localStorage.getItem('token');
        // this.setState({ visible: nextProps.visible, alias, token });
	}
	
	onClose = () => {
		this.setState({ visible: false });
	};

	onClick = () => {
        const alias = localStorage.getItem('alias');
        const token = localStorage.getItem('token');
        // this.setState({ visible: nextProps.visible, alias, token });

       let url = this.state.baseApiUrl + "/patients/items?token=" + token + "&alias=" + alias;

        axios.get(url).then((res) => {
			const result = res.data;
			
            const fields = result.map(function(item, index){
                const newItem = {value:item.name, label:item.name};
                return newItem;
            });
            this.setState({fields});
            this.setState({ visible: true });
		});
	};

	onParamSelect = (value) => {
        console.log(value);
		this.setState({pName:value.label});
	};

    onSearch = () => {
		const date1 = this.date1.current.state.date;
        const date2 = this.date2.current.state.date;

        const fDate1 = moment(date1, "YYYY-MM-DD")
        const fDate2 = moment(date2, "YYYY-MM-DD")
        const diff = fDate2.diff(fDate1, 'days');
        if(diff<0){
            Swal.fire({
                icon:"error",
                title : "Error",
                html : "Date range is invalid"
            })
        }
        else if (diff>31){
            Swal.fire({
                icon:"error",
                title : "Error",
                html : "Date ranges shouldn't be more than 31 days."
            })
        }       
        else{
            const sampleName = $("#sampleid").val();        
            const pName = this.state.pName;
            const pValue =  $("#pvalue").val();       
            
            if((date1!==null || date1!=="") && (date2!==null || date2!==""))
            {
                this.props.onSearch(date1, date2, sampleName, pName, pValue);
                this.onClose();
            }
            else{
                Swal.fire({
                    icon:"error",
                    title : "Error",
                    html : "Date range and sample name are mandatory"
                });
            }
        }
	};

	render() {
		return (
			<React.Fragment>
          
				<button
					id="btnSearch"
					className="btn btn-small btn-secondary"
					style={{ verticalAlign: "top", maxWidth: "25px" }}
					onClick={this.onClick}
					title="Advanced search"
				>
					<i class="fas fa-ellipsis-h"></i>
				</button>

				<Modal id="modalSearch" show={this.state.visible} effect="fadeInUp" onHide={this.onClose}>
					<Modal.Header closeButton>
						<Modal.Title>Search Parameters</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<div className="row">
								<div className="col-6">
									<label className="label-form">Date From : </label>
									<DateSelector2 id="date1" name="date1" ref={this.date1}></DateSelector2>
								</div>
								<div className="cod-6">
									<label className="label-form">Date To : </label>
									<DateSelector2 id="date2" name="date2" ref={this.date2}></DateSelector2>
								</div>
							</div>
							<div className="form-group">
								<label className="label-form" htmlFor="sampleid">
									Sample Name :
								</label>
								<input id="sampleid" name="sampleid" className="form-control" style={{ display: "inline" }}></input>
							</div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                    <label className="label-form" >Parameter Name :</label>
                                        <Select
                                            options={this.state.fields}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={this.onParamSelect}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                    <label className="label-form" >Parameter Value :</label>
                                    <input id="pvalue" name="pvalue" className="form-control" style={{ display: "inline" }} ></input>
                                    </div>
                                </div>
                            </div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={this.onSearch}>
							Search
						</Button>
					</Modal.Footer>
				</Modal>
			</React.Fragment>
		);
	}
}

export default SearchButton;
