import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

fetch('config.json')	 
    .then(data=>data.json())
    .then(result=>{
        const baseApiUrl=result.api_url;
        console.log(baseApiUrl);
        ReactDOM.render(			
            <App baseApiUrl={baseApiUrl}/>,
            document.getElementById('root')
            );
})    

// const baseApiUrl=window.env.api_url;
// console.log("url:"+baseApiUrl);
// ReactDOM.render(			
//     <App baseApiUrl={baseApiUrl}/>,
//     document.getElementById('root')
//     );
 


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
