import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Toaster from "./toast";
import axios from "axios";
import Swal from "sweetalert2";

class SettingsEdit extends Component {
	constructor(props) {
		super(props);

		this.refName = React.createRef();
		this.refNumber = React.createRef();
		this.refUnit = React.createRef();
		this.refSampleType = React.createRef();
		this.refDefault = React.createRef();
		this.refLinkCode1 = React.createRef();
		this.refLinkCode2 = React.createRef();
		this.refLinkCode3 = React.createRef();
		this.refLinkCode4 = React.createRef();
		this.refCustom = React.createRef();
        this.refCode4Worklist = React.createRef();
        this.refFormula = React.createRef();

		this.state = {
			visible: this.props.visible || false,
			id: 0,
			baseApiUrl: "",
			record: {},
			alias : ""
		};
	}

	onClose = () => {
		this.setState({id:0, record:{}});		
		this.props.onClose(false);
	};

	componentWillReceiveProps(nextProps) {		
		this.setState({id:0, record:{}});	
		this.setState({ visible: nextProps.visible, baseApiUrl: nextProps.baseApiUrl, id: nextProps.id, alias : nextProps.alias });
		//if (nextProps.id > 0) this.onLoad(nextProps.id);
	}

	onLoad = (id) => {
		const token = localStorage.getItem("tokenManager");
		const url = this.state.baseApiUrl + "/patients/setting?token=" + token + "&id=" + id;
		axios.get(url).then((res) => {
			let data = res.data;
			this.setState({ record: data });
		});
	};
	onSave = () => {
		const name= this.refName.current.value;
		if(name.trim()==""||name==null)
		{
			Swal.fire({
				title: "Error",
				html: "Paramter name should not be empty",
				icon: "error",
			});
			return;
		}
		const number= this.refNumber.current.value;
		const unit= this.refUnit.current.value;
		const sampleType= this.refSampleType.current.value;
		const defaultValue= this.refDefault.current.value;
		const linkCode1= this.refLinkCode1.current.value;
		const linkCode2= this.refLinkCode2.current.value;
		const linkCode3= this.refLinkCode3.current.value;
		const linkCode4= this.refLinkCode4.current.value;
		const custom= this.refCustom.current.value;
        const code4worklist = this.refCode4Worklist.current.value;
        const formula = this.refFormula.current.value;

		const headers={
			'name':name,
			'unit':unit,
			'number':number,
			'sampleType':sampleType,
			'default':defaultValue,
			'linkCode1':linkCode1,
			'linkCode2':linkCode2,
			'linkCode3':linkCode3,
			'linkCode4':linkCode4,
			'custom':custom,
            'code4worklist': code4worklist,
            'formula' : formula ,
		};

		const token = localStorage.getItem("tokenManager");
		if(this.state.id===0)
		{
			let url = this.state.baseApiUrl + "/patients/settings/create?token=" + token+"&alias="+this.state.alias;
			axios
				.put(url,null,{headers})
				.then((res) => {
					const data = res.data;
					//console.log(data);
					const result = data["result"];
					const message = data["message"];
					if (result && result === true) {
						
						Toaster('success', 'Record successfully added.')
						this.props.onClose(true);
					} else {
						Swal.fire({
							title: "Adding failed",
							html: message,
							icon: "error",
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		else{
			let url = this.state.baseApiUrl + "/patients/settings/update?token=" + token + "&id=" + this.state.id;
			axios
				.post(url,null,{headers})
				.then((res) => {
					const data = res.data;
					//console.log(data);
					const result = data["result"];
					const message = data["message"];
					if (result && result === true) {
						
						Toaster('success', 'Record successfully updated.')
						this.props.onClose(true);
					} else {
						Swal.fire({
							title: "Updating failed",
							html: message,
							icon: "error",
						});
					}
				})
				.catch((error) => {
					console.log(error);
					// Swal.fire({
					//     html: error.response.data, // add html attribute if you want or remove
					//     showConfirmButton: true,
					//     icon: "error",
					// });
				});
		}
		

	};

	render() {
		return (
			<Modal
				id="modal-edit"
				dialogClassName="modal-edit"
				contentClassName="modal-edit"
				show={this.state.visible}
				effect="fadeInUp"
				onHide={() => this.onClose()}
				centered
				scrollable={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.record.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						{/* <form onSubmit={this.onSave}> */}
						<div className="form-group">
							<label className="label-form" htmlFor="Name">Parameter Name :</label>
							<input
								id="Name"
								name="Name"
								className="form-control input-form"
								//placeholder="Parameter name"
								ref={this.refName}
								defaultValue={this.state.record.name}
								title="Parameter name"
							></input>
						</div>
						<div className="form-group">
							<label className="label-form" htmlFor="No">Parameter Code :</label>
							<input
								id="No"
								name="No"
								className="form-control input-form"
								//placeholder="Parameter code (optional)"
								defaultValue={this.state.record.number}
								ref={this.refNumber}
							></input>
						</div>

						<div className="form-group">
						<label className="label-form" htmlFor="Unit">Unit :</label>
							<input
								id="Unit"
								name="Unit"
								className="form-control input-form"
								//placeholder="Unit"
								ref={this.refUnit}
								defaultValue={this.state.record.unit}
							></input>
						</div>

						<div className="form-group">
						<label className="label-form" htmlFor="SampleType">Sample Type :</label>
							<input
								id="SampleType"
								name="SampleType"
								className="form-control input-form"
								//placeholder="Sample type"
								ref={this.refSampleType}
								defaultValue={this.state.record.sampleType}
							></input>
						</div>

						<div className="form-group">
						<label className="label-form" htmlFor="Default">Default Value :</label>
							<input
								id="Default"
								name="Default"
								className="form-control input-form"
								//placeholder="Default value"
								ref={this.refDefault}
								defaultValue={this.state.record.default}
							></input>
						</div>

						<div className="form-group">
						<label className="label-form" htmlFor="LinkCode1">Link Code I :</label>
							<input
								id="LinkCode1"
								name="LinkCode1"
								className="form-control input-form"
								//placeholder="Link code I"
								ref={this.refLinkCode1}
								defaultValue={this.state.record.linkCode1}
							></input>
						</div>

						<div className="form-group">
							<label className="label-form" htmlFor="LinkCode2">Link Code II :</label>
							<input
								id="LinkCode2"
								name="LinkCode2"
								className="form-control input-form"
								//placeholder="Link code II"
								ref={this.refLinkCode2}
								defaultValue={this.state.record.linkCode2}
							></input>
						</div>

						<div className="form-group">
							<label className="label-form" htmlFor="LinkCode3">Link Code III :</label>
							<input
								id="LinkCode3"
								name="LinkCode3"
								className="form-control input-form"
								//placeholder="Link code III"
								ref={this.refLinkCode3}
								defaultValue={this.state.record.linkCode3}
							></input>
						</div>

						<div className="form-group">
							<label className="label-form" htmlFor="LinkCode4">Link Code IV :</label>
							<input
								id="LinkCode4"
								name="LinkCode4"
								className="form-control input-form"
								//placeholder="Link code VI"
								ref={this.refLinkCode4}
								defaultValue={this.state.record.linkCode4}
							></input>
						</div>

						<div className="form-group">
							<label className="label-form" htmlFor="Code4Worklist">Worklist Code :</label>
							<input
								id="Code4Worklist"
								name="Code4Worklist"
								className="form-control input-form"
								//placeholder="Custom"
								ref={this.refCode4Worklist}
								defaultValue={this.state.record.code4Worklist}
							></input>
						</div>

						<div className="form-group">
							<label className="label-form" htmlFor="Custom">Custom Information :</label>
							<input
								id="Custom"
								name="Custom"
								className="form-control input-form"
								//placeholder="Custom"
								ref={this.refCustom}
								defaultValue={this.state.record.custom}
							></input>
						</div>

                        <div className="form-group">
							<label className="label-form" htmlFor="Formula">Formula :</label>
							<input
								id="formula"
								name="formula"
								className="form-control input-form"
								//placeholder="Custom"
								ref={this.refFormula}
								defaultValue={this.state.record.formula}
							></input>
                        </div>
                        
						<br />
						{/* <div className="form-group div-vertical-center text-sm-left">
								<input type="submit" className="btn-success form-control" value="Save" />
							</div> */}
						{/* </form> */}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="success" onClick={this.onSave}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default SettingsEdit;
