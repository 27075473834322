import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";

const columns= [
	{
		data: "id",
	},
    {
        data:"isProcessed",
        searchable: false,
        className: "column-center",
        width: "40px",
        render: function (data) {
            if (data === true) {
                return '<div><i class= "fa fa-check" style="color:limegreen;font-size: 1.5em;"></i></div>';
            } else {
                return '<div> <i class= "fa fa-times" style="color:red;font-size: 1.5em;"></i></div>';
            }
        },
    },
	{
		data: "key",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "70px",
		render: function (data) {
			return (
				'<button data-toggle="modal" data-target="#modalPopup" class="btn btn-info btnDetail" data-id=' +
				data +
				'>Items</button>'
			);
		},
	},
	{
		data: "key",
		sortable: false,
		searchable: false,
		className: "column-center",
		width: "50px",
		render: function (data) {
			return (
				'<button href="#" class="btn btn-small btn-danger btnDelete" data-id=' +
				data +
				'><i class= "far fa-trash-alt" style="font-size: 1.2em"></button>'
			);
		},
	},
];


class OrdersManager extends Component {
    constructor(props) {
        super(props);
        this.orderEdit = React.createRef();
		
        this.state = {
			baseApiUrl: "",
			alias: "",
			tblOrders: null,
			visible: this.props.visible || false,
			showDetails: false,
			selected_id: 0,
		};
    }

    onClose = () => {
		this.setState({ visible: false });
	};

    componentWillReceiveProps(nextProps) {
		this.setState({
			visible: nextProps.visible,
			baseApiUrl: nextProps.baseApiUrl,
			alias: nextProps.alias,
			settingsType: nextProps.settingsType,
		});
	}

    loadOrdersTable = (token, alias) => {
		//console.log(token, alias);
		let url = this.state.baseApiUrl + "/worklist/orders?token=" + token;
		let deleteOrder = this.onDeleteOrder;
		let showDetails = this.onShowDetails;
		let onNewRecord = this.onNewRecord;
		if (this.state.tblOrders !== null) {
			//$("div.main-container").append($("#btnNew"));
			let table = $("#tblOrders").DataTable();
			table.clear();
			table.destroy();
			$("#tblOrders").off("click", ".btnDelete");
			$("#tblOrders").off("click", ".btnDetail");
		}

		axios.get(url).then((res) => {
			let datas = res.data;
			let tblOrders = $("#tblOrders").DataTable({
				dom: 'Bf<"toolbarSettings">rtip',
				responsive: false,
				autoWidth: false,
				info: false,
				processing:true,
				"language": {
					processing: "<div id='loader'>Loading</div>"
				},
				//data: data,
				ajax: function (data, callback, settings) {
					callback({ data: datas }); //reloads data
				},
				columns: columns,
				initComplete: function () {
					this.fnAdjustColumnSizing();
					//call your function here
					$("#tblOrders").attr("style", "visibility: visible")
					$("#tblOrders").on("click", ".btnDelete", function (event) {
						event.preventDefault();
						const id = this.getAttribute("data-id");
						//console.log(id);
						deleteOrder(id, $(this));
					});
					$("#tblOrders").on("click", ".btnDetail", function (event) {
						event.preventDefault();
						const id = this.getAttribute("data-id");
						console.log("btnDetail", id);
						showDetails(id, $(this));
					});
				},
			});

			this.setState({ tblOrders });
			
            
            
            // const btnNew = '<button id="btnNew" class="btn btn-secondary">New Record</button>';
			// $("div.toolbarSettings").append(btnNew);
			// $("#btnNew").on("click", function (event) {
			// 	onNewRecord();
			// });
		});
	};

    onDeleteOrder = (id, button) => {
		Swal.fire({
			title: "Confirm",
			text: "Are you sure to delete this record?",
			icon: "question",
			showCancelButton: true,
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#3085d6",
			confirmButtonText: "Delete",
			cancelButtonText: "Cancel",
			confirmButtonColor: "#DC3545",
			cacnelButtonColor: "#d33",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				const token = localStorage.getItem("token");
				let url = this.state.baseApiUrl + "/worklist/orders/delete?token=" + token + "&key=" + id;
				axios
					.delete(url)
					.then((res) => {
						const data = res.data;
						const result = data["result"];
						const message = data["message"];
						if (result && result === true) {
							const token = localStorage.getItem("token");
							this.loadOrdersTable(token, this.state.alias);

							let tblOrders = this.state.tblOrders.DataTable();
							tblOrders.row(button.parents("tr")).remove().draw();

							Swal.fire({
								title: "Deleted",
								html: "Record successfully deleted",
								icon: "success",
							});
						} else {
							Swal.fire({
								title: "Deletion failed",
								html: message,
								icon: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error);
						// Swal.fire({
						//     html: error.response.data, // add html attribute if you want or remove
						//     showConfirmButton: true,
						//     icon: "error",
						// });
					});
			}
		});
	};


    onShowDetails = (id, button) => {
		const token = localStorage.getItem("token");				
        let url = this.state.baseApiUrl + "/worklist/orderitems?token=" + token+"&orderID="+id;
		axios.get(url).then((res) => {
			let data = res.data;

            data=data.map(d=>"<li>"+d.name+"</li>");
            console.log(data);
            data = JSON.stringify(data,null,'');
            data = data.replaceAll("\"","");
            data = data.replaceAll("[","");
            data = data.replaceAll("]","");
            data = data.replaceAll(",","");
            data="<ol>"+data+"</ol>";
            
            console.log(data);

            Swal.fire({
                title: "Order Items",
                html: data,
                icon: "info",
            });

        });			
	};

	

    render() { 
        return ( 
            <div>
				
				<Modal show={this.state.visible} effect="fadeInUp" onHide={() => this.props.onClose()}>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.alias}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div id="main-container">
							
							<table
								id="tblOrders"
								className="table table-striped table-bordered dt-responsive nowrap"
								style={{ width: "100%", visibility:"collapse" }}
								cellSpacing="0"
							>
								<thead>
									<tr>
										<th>Record ID</th>
                                        <th>Sent</th>
										<th>Items</th>
										<th>Delete</th>
									</tr>
								</thead>
								<tbody></tbody>
							</table>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={() => this.props.onClose()}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
			</div>

         );
    }
}
 
export default OrdersManager;