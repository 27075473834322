import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert"

import "../css/logs.css";

class LogsView extends Component {
	state = {
        baseApiUrl:'',
		visible: this.props.visible || false,
		date : '',
        alias : '',
	};

	onClose = () => {
		this.setState({ visible: false });
	};

	componentWillReceiveProps (nextProps) {
		this.setState({ visible: nextProps.visible, date: nextProps.date, baseApiUrl:nextProps.baseApiUrl, alias:nextProps.alias });
	}

	componentDidMount() {
	}

    loadLogsTable = (token, date) => {
        //console.log(token, date);
        const url =this.state.baseApiUrl + "/patients/logs?token=" + token+'&date=' + date;
        let showComment =this.onShowComment;
        $("#tblLogs").dataTable();
        axios.get(url).then((res) => {
            let data = res.data;
            this.setState({date});
            $("#tblLogs").dataTable({
                "dom": 'Bfrtip',
                responsive: false,
                autoWidth: false,
                processing:true,
				"language": {
					processing: "<div id='loader'>Loading</div>"
				},
                info: false,
                data : data,
                columns: [
                    {
                        data : "time",
                        width: "50px",
                        searchable: false
        
                    },
                    {
                        data: "comment",
                        sortable: false
                        
                    },
                    {
                        data: "comment",
                        sortable: false,
                        searchable: false,
                        className: "column-center",
                        width: "70px",
                        "render": function (data) {
                            if(data.length>30)
                                return '<button class="btn btn-info btnLogs" data-comment="'+data+'">Details</button>';
                            else 
                                return null;
                        }
                    }
                ],
                initComplete: function () {
					this.fnAdjustColumnSizing();
					//call your function here
                    $("#tblLogs").attr("style", "visibility: visible")
					$("#tblLogs").on("click", ".btnLogs", function (event) {
						event.preventDefault();
						let comment = this.getAttribute("data-comment");
						showComment(comment);
					});
				},
            });
        });
        
    };

    onShowComment=(comment)=>{
        Swal({
            title : 'Comment',
            text : comment
        })
    };


	render() {
		
		return (
			<Modal show={this.state.visible} effect="fadeInUp" onHide={() => this.props.onClose()}>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.alias} [{this.state.date}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<table
							id="tblLogs"
							className="table table-striped table-bordered dt-responsive nowrap"
							style={{ width: "100%", visibility:"collapse"  }}
							cellSpacing="0"
						>
							<thead>
                            <tr>
                                <th>Time</th>
                                <th>Comment</th>
                                <th></th>
                            </tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={() => this.props.onClose()}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default LogsView;
