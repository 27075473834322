
import React, { Component,useState } from "react";
import {Link} from "react-router-dom";
  
import { Button, Modal } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import "../css/connections.css";
import { VscDebugPause ,VscSettingsGear,VscDebugStop,VscDebugStart } from "react-icons/vsc";
import Swal from "sweetalert2";

class InstrumentSelector extends Component {
    

    
	state = {
        selectedConnection : '',
        visible: false,
        connections :[],
        managerToken: '',
        type: -1,
        i: 0,
        j: 10,
        currentPbutton: true,
        currentNbutton: true,
        stateNow: true,
        disabled: false , 
    };

    componentDidMount(){
        const managerToken = localStorage.getItem('tokenManager');
        this.setState({managerToken});
        const url = this.props.baseApiUrl + "/patients/getinstruments?token=" +managerToken;
       
        axios.get(url).then((res) => {
            let data = res.data;
            
                 data = data.filter(d => d.name == this.props.caption)[0];
            
            if (!data.insturmentStatus)
            {
                this.setState({ stateNow: false });
                localStorage.setItem('stateNow', false);
            }
            else 
                localStorage.setItem('stateNow', true);  
            
        if (data.disabled === false)
            {
                this.setState({ disabled: true });
                localStorage.setItem('disabled', false);
            } 
                
           
        });
    }


    onClick=()=>{
        this.setState({visible : true});
        let data = this.loadConnectionsTable(-1);
        
    };

    onSelect=(id)=>{
        const connection = this.state.connections.find(c => c.id === id);
        const currentconnection =  this.state.connections.filter(d => d.id == id)[0];

        this.setState({selectedConnection:connection});
        this.props.onSelect(id);
        this.onClose();
        this.setState({ stateNow: currentconnection.insturmentStatus });
        localStorage.setItem('stateNow', currentconnection.insturmentStatus?true:false);
        this.setState({ disabled: !currentconnection.disabled });
        localStorage.setItem('disabled', !currentconnection.disabled);

      
   
    };

    onClose=()=>{
        this.setState({visible : false});
    }
    
    handleClick = async () => {
         const [searchTerm, SetSearchTerm] = useState("");
        
        this.loadConnectionsTable(0); 
    };
 
    
    
    loadConnectionsTable = (condition=-1,name=null) => {
        
          this.setState({ i: 0 });
        this.setState({ j: 10 });
        this.setState({currentPbutton:false})
        const url =this.props.baseApiUrl + "/patients/getinstruments?token="+this.state.managerToken;
    
        const onSelect = this.onSelect;

       
        axios.get(url).then((res) => {
            let data = res.data;
   
            
            if (condition != -1)
            {
           
            
                
                if (name===null)
                {
                  
                    data = data.filter(d => d.disabled == condition);
                    if (data.length < 10)
                        this.setState({ currentNbutton: false });
                    else 
                         this.setState({ currentNbutton: true });
         
                }
                else {
                    data = data.filter(d => d.disabled == condition);
                    data = data.filter(element => {
                        return element.name.toLowerCase().includes(name.toLowerCase())});
                    if (data.length < 10)
                        this.setState({ currentNbutton: false });
                    else 
                         this.setState({ currentNbutton: true });
                  
                   
                }

                    this.setState({ connections: data });
                     this.setState({ type: condition });
}
            else
            {
                if (name != null)
                        
            data = data.filter(element => {
                        return element.name.toLowerCase().includes(name.toLowerCase())});
            this.setState({ connections: data });
             this.setState({ type: -1 });}
           
        });
      
    }

       initComplete = e => {
           const id = e.target.attributes.getNamedItem("data-id").value;
           this.onSelect(id);
     

  };

    
    handleChange = e => 
    {
        const value = parseInt(e.target.value);

        this.loadConnectionsTable(value);
      
    }

       handlePage = e => 
    {
        const value = parseInt(e.target.value);
      
           this.setState({ i: (value-1)*10 });
           this.setState({ j: (value-1) * 10 + 10 });

           console.log(this.state.i + " " + this.state.j);
           
           
    }
    
     handleChangeText = e => 
    {
        const value = e.target.value;
     

         this.loadConnectionsTable(this.state.type,value);
      
      
    }
    
    createElements(n) {
    
    var elements = [];
    var i;
    for(i =1; i <= n; i++){
        elements.push(<option value={i}>{i}</option>);
    }
    return elements;
    }
    
         Next = e => 
         {    
             console.log("Last test "+ " "+this.state.i+" "+this.state.j)
             if (this.state.i <= this.state.connections.length && this.state.connections.length) {
                 this.setState({ i: (this.state.i ) + 10 });
                 this.setState({ j: (this.state.i ) + 20 });
                 if (this.state.j+10 > this.state.connections.length)
                     this.setState({ currentNbutton: false })
                 else 
                 this.setState({ currentNbutton: true });
                 this.setState({ currentPbutton: true });
             }
             else 
           this.setState({ currentNbutton: false });
           
    }

             Previous = e => 
    {    
                 if (this.state.i >= 10 && this.state.j >= 10) {
                     this.setState({ i: (this.state.i) - 10 });
                     this.setState({ j: (this.state.j) - 10 });
                     if (this.state.i-10 < 10)
                         this.setState({ currentPbutton: false });
                     else 
                     this.setState({ currentPbutton: true });
                     this.setState({ currentNbutton: true });
                 } 
                 else 
                 {
                     this.setState({ currentPbutton: false });
                     }
           
    }

    	onStartPlugin = (e) => {
		Swal.fire({
			title: "Confirm",
			text: "Are you sure to start the plugin?",
			icon: "question",
			showCancelButton: true,
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#3085d6",
			confirmButtonText: "Start",
			cancelButtonText: "Cancel",
			confirmButtonColor: "#DC3545",
			cacnelButtonColor: "#d33",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Please Wait !",
					html: "Processing samples", // add html attribute if you want or remove
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: false,
					willOpen: () => {
						Swal.showLoading();
					},
				});
				const parameter = '0';
                const token = localStorage.getItem("token");
                let name =e.target.getAttribute('data-item');
                if(name===undefined)
                 name = document.getElementById('titleCaption').innerHTML;
				let url =
					this.props.baseApiUrl +
					"/patients/command?token=" +
					token +
					"&command=startplugin&alias=" +
					name +
					"&parameter=" +
                    parameter;
                console.log("url : ",url);
				axios
					.post(url)
					.then((res) => {
						const data = res.data;
						const success = data["success"];
                        const fail = data["fail"];
                       
                        this.setState({ stateNow: true });
                        this.setState({ disabled: false });
                        var content = `Plugin is requested to start. This may take a few seconds.`;
                        window.location.reload();
                        console.log(content);
						Swal.fire({
							title: "Finished",
							html: content,
							icon: "info",
                        });
                        
                    }).then(function () {
                        window.location.reload();
                    })
					.catch((error) => {
						Swal.fire({
							html: error.response.data, // add html attribute if you want or remove
							showConfirmButton: true,
							icon: "error",
						});
                    });
                
			}
		});
		
    };

    onStopPlugin = (e) => {
            
        
		Swal.fire({
			title: "Confirm",
			text: "Are you sure to stop the plugin?",
			icon: "question",
			showCancelButton: true,
			showConfirmButton: true,
			focusConfirm: false,
			iconColor: "#3085d6",
			confirmButtonText: "Stop",
			cancelButtonText: "Cancel",
			confirmButtonColor: "#DC3545",
			cacnelButtonColor: "#d33",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Please Wait !",
					html: "Processing samples", // add html attribute if you want or remove
					allowOutsideClick: false,
					showCancelButton: false,
					showConfirmButton: false,
					willOpen: () => {
						Swal.showLoading();
					},
				});
				const parameter = '0';
                
                const token = localStorage.getItem("token");
                let name =e.target.getAttribute('data-item');
                if(name===undefined)
                 name = document.getElementById('titleCaption').innerHTML;
				let url =
					this.props.baseApiUrl +
					"/patients/command?token=" +
					token +
					"&command=stopplugin&alias=" +
					name +
					"&parameter=" +
                    parameter ;
                  console.log(url+"zrax");
				axios
					.post(url)
					.then((res) => {
						const data = res.data;
						const success = data["success"];
						const fail = data["fail"];
                        this.setState({ disabled: true });
                        this.setState({ stateNow: false });
                        
window.location.reload();  
                        var content = `Plugin is requested to stop. This may take a few seconds.`;
                                                console.log(content);

						Swal.fire({
							title: "Finished",
							html: content,
							icon: "info",
                            
                        });

                                            
					}).then(function () {
                        window.location.reload();
                    })
					.catch((error) => {
						Swal.fire({
							html: error.response.data, // add html attribute if you want or remove
							showConfirmButton: true,
							icon: "error",
						});
                    });
                  
            }
            
		});
		
    };
    
    render() {


		return (
            <React.Fragment>
                
                <label id="titleCaption" className="label-title m-2">{this.props.caption}</label>
               
           
                {this.state.stateNow ?
                    <VscDebugStart className="pauseIcon" size={32} style={{ color: 'green' }} /> 
                    :
                    this.state.disabled ?
                        <VscDebugStop className="pauseIcon" size={32} style={{ color: 'grey' }} />
                        : <VscDebugPause className="pauseIcon" size={32} style={{ color: 'red' }} />}
            
             <Link className="label-title2" onClick={this.onClick} to="#"><VscSettingsGear  className="pauseIcon"  size={32} style={{color:'#2a96c6',marginLeft: '5px'}} /></Link>
                <Modal id="modalConnections" show={this.state.visible}  effect="fadeInUp" onHide={this.onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Instruments List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-inline">
                          <div className=" input-group col-md-4">
                        <select className="form-control"   onChange={this.handleChange}          
                        
                      >
                        <option value="-1">Show All</option>
                        <option value="1">Enabled</option>
                        <option value="0">Disabled</option>
                                </select></div>
                             <div className="input-group col-md-8">
                                <input type="text" className="form-control" name="Search" id="Search" placeholder="Search" onChange={this.handleChangeText} ></input>
                        </div>    </div>
                   
                        <br></br>
                        <div>
                            <table
                                id="tblConnections"
                                className="table table-striped table-bordered dt-responsive nowrap"
                                style={{ width: "100%" }}
                                cellSpacing="0"
                                data={this.state.connections}
                            >
                                <thead>
                                <tr style={{backgroundColor:"#4d95da",color:"white"}}>
                                    <th style={{width:5+"px"}}></th>
                                        <th style={{ width: 100 + "px" }}>Name</th>
                                        <th style={{width:100+"px"}}>Caption</th>
                                        <th style={{width:40+"px"}}>Status</th>
                                       <th style={{width:70+"px"}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                               {
                this.state.connections.slice(this.state.i,this.state.j).map((data, index)=>{
                    return(
                        <tr key={index} onClick={this.StopOrStart} >
                            <td className="column-center td-tight col-sm-1 col-md-1 column-bold m-2" style={{width:5+"px"}}>{data.rowIndex}</td>
                            <td className="connection-name" style={{ width: 100 + "px" }}>{data.name}</td>
                            <td className="connection-name" style={{width:100+"px"}}>{data.caption}</td>
                            <td className="column-center" style={{ width: 25 + "px" }}>{data.insturmentStatus ? <label data-id={data.insturmentStatus} data-item={data.name }  onClick={this.onStopPlugin} className="circleGreen"></label>:<label data-item={data.name }   onClick={this.onStartPlugin} className="circleRed"></label>}</td>
                            <td className="column-center" style={{ width: 70 + "px" }}><button className="btn btn-info btnConnection" data-id={data.id} onClick={this.initComplete}>Select</button></td>
                            
                        </tr>
                    )
                })
            } 
                                </tbody>
                               
                            </table>
                            <div className="float-right">
                                 <div className="form-group">
                                 <div className=" input-group col-md-12">
                        <Button   className="mb-2" size="sm" variant="secondary" disabled={!this.state.currentPbutton} onClick={this.Previous}>Previous{console.log(this.state.i," ",this.state.j)}</Button>
                       &nbsp;&nbsp; <Button className="mb-2" size="sm" variant="secondary"  disabled={!this.state.currentNbutton} onClick={this.Next}>Next</Button>
                       </div></div></div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.onClose} className="mb-2" size="sm">
                            Close
                        </Button>

                          <select onChange={this.handlePage} hidden={true}>
          
                           {this.createElements(this.state.connections.length/10+1)}
            
                        </select>
                       
                        
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
		);
	}
}

export default InstrumentSelector;
