import React, { Component } from "react";


class PatientsTable extends Component {

	render() {
		return (
			<div className="div-padding">
				<table
					id="example"
					className="table table-striped table-bordered table-hover"
					cellSpacing="0"
					style={{ width: "100%", visibility:"collapse" }}
					role="grid"
					
				>
					<thead>
						<tr>
							<th width="30px" className="td-tight" data-priority="1"></th>
							<th width="30px" className="td-tight" data-priority="1"></th>
							<th width="10px" data-priority="2"></th>
							<th data-priority="2">Record ID </th>
							{/* <th data-priority="4">Sample Name</th> */}
							<th data-priority="5">Informations</th>
							<th  data-priority="1">Source File</th>
							<th data-priority="3" width="50px">
								Time
							</th>
							<th data-priority="7">Sent</th>
							<th data-priority="1" style={{ maxwidth: "70px", width: "70px" }}></th>
						</tr>
					</thead>
					<tbody></tbody>
					<tfoot>
						<tr style={{ height: "30px", margin: "0px", padding: "0px", textAlign: "center" }}>
							<td colSpan="6" style={{ margin: "0px", padding: "0px" }}></td>
							<td
								colSpan="3"
								style={{ margin: "0px", padding: "0px", fontSize: "12px", fontWeight: "bold", verticalAlign: "middle" }}
							>
								<p className="text-danger" style={{ margin: "0px" }}>
									Date and time are in UTC time zone.
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	}
}

export default PatientsTable;
