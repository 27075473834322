import React, { Component } from 'react';
import "./App.css";
import queryString from "query-string";
//import {useLocation} from 'react-router-dom'

import PatientsView from "./components/patientsView";
import PatientsViewManager from "./components/patientsViewManager"
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/login";

import "datatables.net-bs4";
import "datatables.net-select-bs4";
import "bootstrap-datepicker/js/bootstrap-datepicker";

import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-select-bs4/css/select.bootstrap4.css";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
import "./css/myStyles.css";
import InstrumentSettings from "./components/instrumentSettings";


class App extends Component {
	constructor(props){
		super(props);
		const search = window.location.search;
		const values = queryString.parse(search);
		this.state= { 
			search : search,
			values : values,
			apiToken : process.env.REACT_APP_API_TOKEN,
			baseApiUrl : props.baseApiUrl
		 }
	}

	render() { 
		return (
			
			<BrowserRouter>
				<Switch>
					<Route path='/patientsManager'>
						<PatientsViewManager date={this.state.values.date} baseApiUrl={this.state.baseApiUrl} apiToken={this.state.apiToken}/>
					</Route>
					<Route path='/patients'>
						<PatientsView token={this.state.values.token} date={this.state.values.date} baseApiUrl={this.state.baseApiUrl} fromManager="0"/>
					</Route>
					
					<Route path='/config'>
						<InstrumentSettings />
					</Route>
					
					<Route path='/' exact>
						<Login baseApiUrl={this.state.baseApiUrl} apiToken={this.state.apiToken}/>
					</Route>
			
				</Switch>
			</BrowserRouter>
		);
	}
}
 
export default App;

// function App() {
// 	const search = window.location.search;
// 	const values = queryString.parse(search);
// 	const apiToken = process.env.REACT_APP_API_TOKEN;
// 	let baseApiUrl="";
// 	const loadConfig = LoadConfig(); //process.env.REACT_APP_BASE_API_URL;
// 	//let baseApiUrl = (loadConfig instanceof Promise).api_url; //process.env.REACT_APP_BASE_API_URL;
// 	//loadConfig.wait
// 	console.log(loadConfig);
// 	// fetch('config.json')
// 	// .then(data=>data.json())
// 	// 	.then(json=>
// 	// 		{
// 	// 			//console.log(json.api_url);
// 	// 			baseApiUrl=json.api_url;
// 	// 		});
	
// 	return (
// 		<BrowserRouter>
// 			<Switch>
// 				<Route path='/patientsManager'>
// 					<PatientsViewManager date={values.date} baseApiUrl={baseApiUrl} apiToken={apiToken}/>
// 				</Route>
// 				<Route path='/patients'>
// 					<PatientsView token={values.token} date={values.date} baseApiUrl={baseApiUrl} fromManager="0"/>
// 				</Route>
        		
// 				<Route path='/config'>
// 					<InstrumentSettings />
// 				</Route>
        		
// 				<Route path='/' exact>
// 					<Login baseApiUrl={baseApiUrl} apiToken={apiToken}/>
// 				</Route>
        
// 			</Switch>
// 		</BrowserRouter>
// 	);
// }

// export default App;
