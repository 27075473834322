import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Toaster from "./toast";
import axios from "axios";
import Swal from "sweetalert2";

class ConnectionEdit extends Component {
	constructor(props) {
		super(props);
       
		this.refName = React.createRef();
		this.refValue = React.createRef();
		this.state = {
			visible: this.props.visible || false,
			id: this.props.id|| 0,
			baseApiUrl: this.props.baseApiUrl|| "",
			record: {},
			alias : ""
		};
	}

	onClose = () => {
		this.setState({id:0, record:{}});		
		this.props.onClose(false);
	};

    componentWillReceiveProps(nextProps) {
   
        this.setState({ id: 0, record: {} });	
    
        var item = nextProps.id+"";
        var idAlias =item.split("++");
    
        var id = idAlias[0];
        var name = idAlias[1];
      
        this.setState({ visible: nextProps.visible, baseApiUrl: nextProps.baseApiUrl, id: id, alias: name, nameConnection: "hl7parser" });
        
        console.log("ALIAAAAAAAAAAAAAAS ", name);
		
		//if (nextProps.id > 0) this.onLoad(nextProps.id);
	}

	onLoad = (iditem) => {
        
        var item = iditem + "";
        
        var idAlias =item.split("++");
    
        var idTemp = idAlias[0];
		const token = localStorage.getItem("tokenManager");
        const url = this.state.baseApiUrl + "/patients/status?token=" + token;
       
		axios.get(url).then((res) => {
            let data = res.data;
            
            let temp = {};
            temp["name"] = this.state.alias;
            temp["value"] = data[0][this.state.alias];
            
         
            this.setState({ record: temp });
            this.setState({ refName: temp["name"]});
		});
	};

	onSave = () => {
       const name = this.refName.current.value;
        
		if(name.trim()==""||name==null)
		{
			Swal.fire({
				title: "Error",
				html: "Paramter name should not be empty",
				icon: "error",
			});
			return;
		}
        const value = this.refValue.current.value;
        
		const token = localStorage.getItem("tokenManager");
    
            let url = this.state.baseApiUrl + "/patients/connection/update?token=" + token + "&id=" + this.state.id+"&nameParameter="+name+"&valueParameter="+value;
            console.log(url);
		   /* var body = new FormData();
			body.append('name',  "5");
        body.append('value', "11");
        */
        const body={
			'name':"5",
			'value':"11",
			
		};
        console.log(body);
        
			axios
				.post(url,body,null)
                .then((res) => {
                    console.log("res ",res)
					const data = res.data;
					//console.log(data);
					const result = data["result"];
					const message = data["message"];
					if (result && result === true) {
						
						Toaster('success', 'Record successfully updated.')
						this.props.onClose(true);
					} else {
						Swal.fire({
							title: "Updating failed",
							html: message,
							icon: "error",
						});
					}
				})
				.catch((error) => {
                    console.log(error.response);
				});
		
		

	};

	render() {
		return (
			<Modal
				id="modal-edit"
				dialogClassName="modal-edit"
				contentClassName="modal-edit"
				show={this.state.visible}
				effect="fadeInUp"
				onHide={() => this.onClose()}
				centered
				scrollable={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.record.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="form-group">
							<label className="label-form" htmlFor="Name">Parameter Name :</label>
							<input
								id="Name"
								name="Name"
								className="form-control input-form"
								//placeholder="Name"
								ref={this.refName}
                                defaultValue={this.state.record.name}
                                disabled = {true}
							></input>
						</div>
						<div className="form-group">
							<label className="label-form" htmlFor="Value">Parameter Value :</label>
							{/* <input
								id="Value"
								name="Value"
								className="form-control input-form"
								//placeholder="Value"
								defaultValue={this.state.record.value}
								ref={this.refValue}
							></input> */}
							<textarea
								id="Value"
								name="Value"
								className="form-control input-form"
								rows="10"
								wrap="off"
								//style={{whiteSpace:"nowrap"}}
								defaultValue={this.state.record.value}
								ref={this.refValue}
							></textarea>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="success" onClick={this.onSave}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ConnectionEdit;
