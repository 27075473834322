import React, { Component } from "react";
import axios from "axios";
import "@fortawesome/fontawesome-free";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import moment from "moment";

import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../img/icollector_logo.png";

class Login extends Component {
	constructor(props) {
		super(props);

		let icollectorDomain=false;
		if(window.location.href.toLowerCase().includes('.icollector.'))
			icollectorDomain=true;

		this.refUsername = React.createRef();
		this.refPassword = React.createRef();
		this.recaptchaRef = React.createRef();
		this.state = {
			baseApiUrl: props.baseApiUrl,
			token: "",
			apiToken: props.apiToken,
			error: "",
			failed: false,
			redirect: false,
			validated : !icollectorDomain
		};

		
	
	}

	componentWillReceiveProps(props){
		this.setState({baseApiUrl:props.baseApiUrl, apiToken:props.apiToken});
			
	}

	onLogin = (event) => {
		if(!this.state.validated)
		{
			Swal.fire({
				html: "Captcha verification not completed", // add html attribute if you want or remove
				showConfirmButton: true,
				icon: "error",})
				.then(()=>{

					return;
				});
		}
		event.preventDefault();
		const url = this.state.baseApiUrl + "/patients/login";
		axios
			.post(url, null, {
				headers: {
					username: this.refUsername.current.value,
					password: this.refPassword.current.value,
					token: this.state.apiToken,
				},
			})
			.then((response) => {
				//console.log('login', response);
				const token = response.data.token;
				const isManager = response.data.isManager;
				
				let url="";
				if(isManager)
				{	
					url = "/patientsManager";//?token=" + token;
					localStorage.setItem('tokenManager', token);
				}
				else
				{	
					url = "/patients";//?token=" + token;
					localStorage.setItem('token', token);
				}

				this.saveTimestamp();

				window.location.href = url;
			})
			.catch((response) => {
				const error = response.data;
				this.setState({ error, failed: true });
				//console.log(error);
			});
	};

	saveTimestamp(){
		let timestamp =  moment().format("YYYY-MM-DD HH:mm:ss");
		localStorage.setItem('timestamp', timestamp);
		//console.log('timestamp saved:'+timestamp);
	}


	onCaptchaChange =(value)=>{
		//console.log(value);
		const recaptchaValue = this.recaptchaRef.current.getValue();
		const valid = value ===recaptchaValue;
		this.setState({validated:valid});
		
	}

	onCaptchaExpired=()=>{
		this.setState({validated:false});
	}
	onCaptchaErrored=()=>{
		this.setState({validated:false});
	}
	render() {
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-4 col-md-4 col-lg-4"></div>
					<div className="col-sm-4 col-md-4 col-lg-4" style={{ padding: "20px",maxWidth:500, minWidth:400 }}>
						<div className="form-group div-vertical-center">
							<img src={logo} className="logo-large img-fluid" alt=""/>
						</div>
						<div className="card alert bg-light border rounded-lg" style={{ padding: "20px" }}>
							<div className="div-vertical-center form-group">
								<label className="col-form-label">Log in to continue to:</label>
								<br />
								<label className="col-form-label login-page-cadre-title">I-Collector WebViewer</label>
							</div>
							<form onSubmit={this.onLogin}>
								<div className="form-group">
									<input name="password" className="form-control" placeholder="Username" ref={this.refUsername}></input>
								</div>

								<div className="form-group">
									<input
										name="username"
										className="form-control"
										placeholder="password"
										ref={this.refPassword}
										type="password"
									></input>
								</div>
								<div >
									{window.location.href.toLowerCase().includes('.icollector.')?
									<ReCAPTCHA 
										ref={this.recaptchaRef}
										className="captcha-container"
										sitekey="6Lef9PcaAAAAAPYiQSiUP31OlXZqTuyBJ1361Bhv"
										type="image"
										onChange={this.onCaptchaChange}
										onExpired={this.onCaptchaExpired}
										onErrored={this.onCaptchaErrored}									
									/>:null
									}
								</div>
								<br />
								<div className="form-group div-vertical-center text-sm-left">
									{this.state.validated?
										<input type="submit" className="btn-primary form-control" value="Login" />
										:
										<input type="submit" className="btn-secondary form-control" value="Login" disabled="disabled"/>
									}									
								</div>
							</form>
							<div className="div-vertical-center">
								{this.state.failed && <label className="text-danger">Athentication failed</label>}
							</div>
							<br />
							<div className="div-vertical-center">
								<label className="login-page-footer-text">
									<b>
										<i className="fa fa-copyright"></i> I-Collector
									</b>{" "}
									v1.2 by <b>AgileBio</b>
								</label>
							</div>
						</div>{" "}
					</div>
					<div className="col-sm-4 col-md-4 col-lg-4"></div>
				</div>
			</div>
		);
	}
}

export default Login;
