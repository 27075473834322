import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toaster = (type, message) => {
    toast.configure();
    switch (type) {
        case 'warning':
            return toast.warning(<div><i class="fas fa-exclamation-triangle"></i> {message}</div>)
        case 'error':
            return toast.error(<div><i class="fas fa-exclamation-circle"></i> {message}</div>) // look this line
        case 'success':
            return toast.success(<div><i class="fas fa-check-circle"></i> {message}</div>)
        case 'info':
            return toast.info(<div><i class="fas fa-info-circle"></i> {message}</div>)
        default:
            return toast(message)
    }
}


export default Toaster;